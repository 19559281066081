<template>
<div class="fit">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless>
        <b-tab class="p-0">
            <template slot="title">
                <q-icon name="archive" />
            </template>
            <b-list-group class="list-group-accent">
                <b-list-group-item @click.prevent="isHidden = !isHidden" class="list-group-item-accent-secondary bg-light font-weight-bold text-uppercase cursor-pointer">
                    <q-icon :title="translate('Click to show or hide the included items')" name="list" />&nbsp;
                    <strong class="position-relative pr-4" style="display: inline-block; width: auto;">
                        {{ main_title }}
                        <q-badge color="primary" floating>{{ items && items.length ? items.length : '0' }}</q-badge>
                    </strong>
                </b-list-group-item>
                <b-list-group-item v-if="!isHidden && items && items.length" class="list-group-item-accent-info list-group-item-divider items-container" :class="'list_wrapper-included-items-' + entity_type + '-' + item_id">
                    <q-item class="list-item" clickable ripple v-for="(item,i) in items" :key="'included-item-' + i + item.id">
                        <q-item-section>
                            <q-item-label>{{ item.equipment_name }}</q-item-label>
                        </q-item-section>
                        <q-item-section side>
                            <q-btn icon="delete" @click="showDeletePanel(item.id)" flat dense color="danger" />
                        </q-item-section>
                    </q-item>
                    <div v-if="!items">
                        <q-item class="m-0 pt-3">{{ translate('No included items') }}</q-item>
                    </div>
                </b-list-group-item>
                <infinite-loading slot="append" @infinite="getItems" :identifier="'infiniteLoading-' + item_id  + '-' + infItems" />
                <q-separator />
                <b-list-group-item class="list-group-item-accent-secondary bg-light font-weight-bold text-uppercase">
                    <q-icon name="add" />&nbsp;
                    {{ translate('include_' + entity_type) }}
                </b-list-group-item>
                <b-list-group-item class="list-group-item-accent-info list-group-item-divider">
                    <include-item-form 
                        :placeholder_find_item="placeholder_find_item" 
                        @update_list="updateIncludedItems" 
                        :item_id="item_id" 
                        :post_route="post_route" 
                        :get_route="get_non_included_route" 
                        :parent_entity_type="parent_entity_type"
                        :parent_post_route="parent_post_route"
                        :entity_type="entity_type" >
                    </include-item-form>
                </b-list-group-item>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import {
    eventBus
} from "../../../main"
import InfiniteLoading from 'vue-infinite-loading'

import DeleteIncludedItem from './DeleteIncludedItem'
import IncludeItemForm from './IncludeItem'

export default {
    name: 'AddIncludedItems',
    props: ['post_route', 'get_included_route', 'get_non_included_route', 'delete_included_route', 'item_id', 'entity_type', 'parent_entity_type', 'parent_post_route', 'main_title', 'placeholder_find_item', 'add_button'],
    components: {
        'include-item-form': IncludeItemForm
    },
    computed: {
        post_url: function () {
            return this.post_route
        },
        get_url: function () {
            return this.get_included_route + '/' + this.item_id
        },
    },
    data: function () {
        return {
            isHidden: false,
            state: {},
            page: 1,
            items: [],
            infItems: +new Date() + 1,
        }
    },
    methods: {
        resetMainList: function () {
            this.page = 1
            this.items = []
            this.infItems++
        },
        updateIncludedItems: function () {
            this.resetMainList()
        },
        getItems: function ($state) {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }
            var url = this.get_url + '?page=' + this.page + '&items_per_page=' + this.$items_per_page
            this.state = $state
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (response.data.items && response.data.items.length > 0) {
                        if (response.data.next_page === true) {
                            this.page++
                            $state.loaded()
                        } else {
                            $state.complete()
                        }
                        this.items.push(...response.data.items)
                    } else {
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        showDeletePanel: function (item_id) {
            const panelInstance = this.$showPanel({
                component: DeleteIncludedItem,
                props: {
                    item_id: item_id,
                    entity_type: this.entity_type,
                    delete_route: this.delete_included_route,
                    panel_title: this.translate('delete_included_' + this.entity_type),
                    panel_message: this.translate('deleting_included_' + this.entity_type)
                },
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.updateIncludedItems()
                        this.$emit('update_list')
                        eventBus.$emit('update_included_items')
                        eventBus.$emit('update_non_included_items')
                    }
                })
        },
        closePanel: function () {
            this.$emit('closePanel', {})
        }
    }
}
</script>

<style lang="scss" scoped>
.items-container {
    max-height: 30vh;
    overflow-y: auto;
}

.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: .5rem 0;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }
}
</style>
