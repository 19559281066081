<template>
<div class="fit sticky-form-actions">
    <q-btn class="close-panel right top mt-2 position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless :style="'overflow-y: auto; height: 100vh; padding-bottom: ' + formActionsRowHeight + 'px; '">
        <b-tab class="p-0">
            <template slot="title">
                <q-item dense class="full-width p-0 min-height-0">
                    <q-item-section avatar>
                        <q-avatar class="shadow-2">
                            <img v-if="customer_image_link" :src="customer_image_link">
                            <i v-else class="fas fa-user fa-1x text-muted"></i>
                        </q-avatar>
                    </q-item-section>
                    <q-item-section>
                        <span class="text-muted">{{ translate('assign_' + entity_type) }}</span>
                        <h4 class="mb-0 align-self-start">{{ customer_name }}</h4>
                    </q-item-section>
                </q-item>
            </template>
            <b-list-group class="list-group-accent full-width">
                <b-list-group-item>
                    <div class="full-height">
                        <div class="mb-3">
                            <small>{{ translate('training_date') }}</small><br />
                            <i class="font-weight-bold text-indigo-8 icon-calendar mr-1"></i> {{ training_date }}
                        </div>
                        <strong>{{ translate('choose_training') }}</strong>
                        <div v-if="!items || items.length == 0">
                            <div class="text-muted">{{ translate('no_results') }}</div>
                        </div>
                        <div v-else>
                            <div class="text-muted">{{ translate('results_found') }}: {{ items.length }}</div>
                        </div>
                        <q-input class="search-input mb-0" @input="isTypingItem = true" v-model="searchItem" id="filternameitem" type="text" :placeholder="placeholder_find_item ? translate(placeholder_find_item) : ''" :dense="true">
                            <template v-slot:prepend>
                                <q-icon name="search" />
                            </template>
                            <template v-slot:append>
                                <q-btn :size="$q.screen.lt.sm ? '0.65rem' : null" type="button" @click.stop="showAddTrainingPanel" flat dense color="dark" no-caps :label="add_button_text" icon="add" />
                            </template>
                        </q-input>
                        <div class="items-container" :class="'list_wraper-' + entity_type + '-' + item_id">
                            <q-item @click.stop="showAssignedTrainingDetailsPanel(item.id, date)" class="list-item full-width" clickable ripple v-for="(item,i) in items" :key="'list-item-' + i + '-' + item.id">
                                <q-item-section>
                                    <q-item-label>{{ item.name }}</q-item-label>
                                </q-item-section>
                            </q-item>
                        </div>
                        <infinite-loading @infinite="getItems" ref="infiniteLoading" :identifier="'included-items-' + entity_type + '-' + item_id + '-' +infItems" slot="append" />
                    </div>
                </b-list-group-item>
                <div class="form-actions bottom bg-light" :style="'width: ' + slideoutTabsWidth + 'px'">
                    <q-item class="p-3">
                        <q-item-section side>
                        </q-item-section>
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('cancel') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </div>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import {
    eventBus
} from "../../../main"
import InfiniteLoading from 'vue-infinite-loading'
import AssignedTrainingDetails from '../show/AssignedTrainingDetails'
import IncludeItemForm from './IncludeItem'
import AddTraining from '../../data-libraries/template-parts/forms/AddItem'

export default {
    name: 'AssignTraining',
    props: ['post_route', 'get_route', 'customer_id', 'customer_name', 'customer_image_link', 'item_id', 'entity_type', 'main_title', 'placeholder_find_item', 'date', 'parent_entity_type', 'parent_post_route'],
    components: {
        'include-item-form': IncludeItemForm
    },
    watch: {
        searchItem: _.debounce(function () {
            this.isTypingItem = false
        }, 200),
        isTypingItem: function (value) {
            if (!value) {
                this.resetMainList()
            }
        }
    },
    computed: {
        include_single_choice: function () {
            return [{
                field: this.field_training_level,
                get_route_to_include: this.get_route_training_levels
            },
            {
                field: this.field_training_type,
                get_route_to_include: this.get_route_training_types
            }]
        },
        field_training_level: function () {
            return 'training_level'
        },
        get_route_training_levels: function () {
            return baseUrl + 'training_levels'
        },
        field_training_type: function () {
            return 'training_type'
        },
        get_route_training_types: function () {
            return baseUrl + 'training_types'
        },
        container_class: function () {
            return 'trainings'
        },
        add_button_text: function () {
            return this.translate('new_training')
        },
        add_item_title: function () {
            return this.translate('add_' + this.parent_entity_type)
        },
    },
    data: function () {
        return {
            formActionsRowHeight: 0,
            slideoutTabsWidth: 0,
            searchItem: '',
            isTypingItem: false,
            isHidden: true,
            state: {},
            page: 1,
            items: [],
            infItems: +new Date(),
            selected_training: '',
            training_date: this.date ? this.date : new Date().toJSON().slice(0, 10),
            events: [],
        }
    },
    created: function () {
        this.getElementsWidthHeight()
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.getElementsWidthHeight)
    },
    mounted: function () {
        window.addEventListener('resize', this.getElementsWidthHeight)
    
        eventBus.$on('update_trainings', () => {
            this.resetMainList()
        })
    },
    methods: {
        getElementsWidthHeight: _.debounce(function () {
            this.formActionsRowHeight = document.querySelector('.form-actions').clientHeight
            this.slideoutTabsWidth = document.querySelector('.slideout .tabs').clientWidth
        }, 200),
        showAddTrainingPanel: function () {
            const panelInstance = this.$showPanel({
                component: AddTraining,
                props: {
                    add_item_title: this.add_item_title,
                    post_route: this.parent_post_route,
                    entity_type: this.parent_entity_type,
                    field_unit: this.field_unit,
                    field_description: this.field_description,
                    container_class: this.container_class,
                    include_single_choice: this.include_single_choice,
                    field_exercises_collections: true,
                }
            })

            panelInstance.promise.then(result => {
                if (result && result.status) {
                    eventBus.$emit('update_trainings')
                }
            })
        },
        showAssignedTrainingDetailsPanel: function (id, date) {
            const panelInstance = this.$showPanel({
                component: AssignedTrainingDetails,
                props: {
                    training_id: id,
                    date: date,
                    customer_id: this.customer_id,
                    customer_name: this.customer_name,
                    customer_image_link: this.customer_image_link,
                }
            })

            panelInstance.promise.then(result => {
                if (result && result.status) {
                    eventBus.$emit('update_trainings')
                    this.$emit('closePanel', {
                        status: true
                    })
                }
            })
        },
        resetMainList: function () {
            this.page = 1
            this.items = []
            this.infItems++
        },
        updateIncludedItems: function () {
            this.resetMainList()
        },
        getItems: function ($state) {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }
            
            var url = this.get_route + '?page=' + this.page + '&items_per_page=' + this.$items_per_page + 
            (this.searchItem ? '&search=' + this.searchItem : '')
            this.state = $state
            // To solve latency with server, push the items into a temporary scoped array and then into the main array.
            let items = this.items
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (response.data.items && response.data.items.length > 0) {
                        items.push(...response.data.items)
                    } 
                    if (response.data.next_page === true) {
                        this.page++
                        $state.loaded()
                    } else {
                        // To solve latency with server, push the items into a temporary scoped array and then into the main array.
                        this.items = items
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        register: function () {
            var data = {
                'date': this.training_date,
                'training_id': this.selected_training,
                'customer_id': this.customer_id
            }

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            };

            axios.post(baseUrl + 'personal/trainings', data, {
                    headers: headers
                })
                .then(response => {
                    this.msg = response.data.msg;
                    if (response.data.status) {
                        var message = 'assign_training_' + this.msg
                        var translated_message = this.translate(message)
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        this.$emit('closePanel', {
                            status: true
                        })
                    } else {
                        var key = response.data.key ? '_' + response.data.key : ''
                        var message = 'assign_training_' + this.msg + key
                        var translated_message = this.translate(message)
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }

                })
                .catch(error => {
                    var msg = error.response.data.msg;
                    if (msg != undefined) {
                        this.msg = msg;
                        this.$toasted.error('An error has occured', {
                            duration: this.$toasted_duration
                        })
                    }

                })
        },
        closePanel: function () {
            this.$emit('closePanel', {})
        }
    }
}
</script>

<style lang="scss" scoped>
.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: .5rem 0;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }
}
</style>
