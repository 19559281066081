<template>
<b-row class="full-width">
    <b-col v-if="$q.screen.lt.sm" sm="12" md="2" class="bg-light p-3 p-md-4">
        <panel-picture :src="panel_picture" />
    </b-col>
    <b-col sm="12" class="p-3 p-md-4 bg-white">
        <q-item class="full-width p-0 mb-2">
            <q-item-section>
                <div class="text-h6">{{ translate('personal_agenda') }}</div>
            </q-item-section>
        </q-item>
        <q-separator />
        <div :class="showCalendar">
            <div class="fit" bg-color="primary">
                <template>
                    <q-list v-if="viewOptions" v-model="tab" class="fit row m-0 justify-center">
                        <q-item :name="'tab' + index" clickable class="col-xs-6 col-sm-6 col-md-3 p-0 justify-center items-center view-action-li text-indigo-10" :class="{active: calendarView == view.value}" v-for="(view, index) in viewOptions" :text="view.value" :key="view.value">
                            <q-btn color="black" class="full-width m-0 full-height bg-blue-grey-2" flat @click="setView(view)">{{ view.label }}</q-btn>
                            <div class="q-tab__indicator absolute-bottom"></div>
                        </q-item>
                    </q-list>
                </template>
                <q-item class="bg-primary full-width">
                    <q-item-section side>
                        <q-btn flat dense round text-color="white" icon="keyboard_arrow_left" @click="onPrev"></q-btn>
                    </q-item-section>
                    <q-item-section>
                        <span class="ml-2 nowrap q-toolbar-title">{{ title }}</span>
                    </q-item-section>
                    <q-item-section side>
                        <q-btn flat dense round text-color="white" icon="my_location" @click="setToday" />
                    </q-item-section>
                    <q-item-section side>
                        <q-btn flat dense round text-color="white" icon="keyboard_arrow_right" @click="onNext"></q-btn>
                    </q-item-section>
                </q-item>
            </div>

            <div class="calendar-container agenda full-width" :class="'calendar-' + calendarView">
                <transition-group appear enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
                    <q-calendar :hide-interval="true" :interval-height="calendarIntervalHeight" 
                        :interval-start="calendarIntervalStart" :interval-count="calendarIntervalCount" 
                        :weekdays="weekdays" @input="setCalendarIntervalDates" ref="agenda" 
                        v-model="selectedDate" :view="calendarView" locale="en-us" 
                        style="width: 100%; overflow-x: hidden; min-height: 400px; max-height: 70vh;" 
                        :class="'calendar ' + calendarView" dayPadding="35px 2px" 
                        :key="keyValue" :interval-style="modifiedStyle" :day-style="modifiedStyle" :resource-style="modifiedStyle" 
                        :enable-theme="false" @click:date="onDateChanged" @click:time="!type_customer ? showAssignTrainingPanel($event) : null" @click:day="!type_customer ? showAssignTrainingPanel($event) : null">
                        <template #day="{ date }">
                            <template>
                                <template v-for="(event, index) in getEvents(date)">
                                    <q-badge :key="index" style="width: 100%; cursor: pointer;" :class="badgeClasses(event, 'day')" :style="badgeStyles(event, 'day')" @click.stop.prevent="showAssignedTrainingDetailsPanel(event.training_id, event.date)" :draggable="true" @dragstart="(e) => onDragStart(e, event)" @dragend="(e) => onDragEnd(e, event)" @dragenter="(e) => onDragEnter(e, event)" @touchmove="(e) => {}">
                                        <q-icon v-if="event.icon" :name="event.icon" class="q-mr-xs"></q-icon><span>{{ event.title }}</span>
                                    </q-badge>
                                </template>
                            </template>
                        </template>

                        <template #day-body="data">
                            <template>
                                <template v-for="(event, index) in getEvents(data.date)">
                                    <q-card square flat :class="'text-light bg-'+event.bgcolor" :style="badgeStyles(event, 'body', data.timeStartPos, data.timeDurationHeight)" @click.stop.prevent="showAssignedTrainingDetailsPanel(event.training_id, event.date)" stretch no-body class="my-event p-1 m-0" :key="index">
                                        <p class="text-center">
                                            <strong>{{event.title}}</strong>
                                            <br />
                                            <em class="text-grey-3">{{event.details}}</em>
                                        </p>
                                    </q-card>
                                </template>
                            </template>
                        </template>

                        <template #intervals-header="days">
                            <div class="fit flex justify-center items-end">
                                <span class="q-calendar-daily__interval-text">{{ showOffset(days) }}</span>
                            </div>
                        </template>
                    </q-calendar>
                </transition-group>
            </div>
        </div>
    </b-col>
</b-row>
</template>

<style src="@quasar/quasar-ui-qcalendar/dist/index.css"></style>
<script>
import {
    eventBus
} from "../../../main"
import AssignTraining from '../forms/AssignTraining'
import AssignedTrainingDetails from '../show/AssignedTrainingDetails'
import AssignedTrainingDetailsCustomer from '../show/AssignedTrainingDetailsCustomer'
import ProfilePicture from '../../template-parts/ProfilePicture'
import {
    QCalendar
} from '@quasar/quasar-ui-qcalendar'
import {
    padTime
} from '../utils/time'
import {
    isCssColor
} from '../utils/color'
import {
    getLocale
} from '../utils/getLocale'

export default {
    name: 'Agenda',
    props: ['customer_id', 'customer_name', 'customer_image_link'],
    components: {
        'panel-picture': ProfilePicture,
        QCalendar,
    },
    data: function () {
        return {
            type_customer: this.$appSettings.currentUserType() === 'customer' ? true : false,
            // [+] Calendar.
            calendarIntervalHeight: 120,
            calendarIntervalStart: 0,
            calendarIntervalCount: 1,
            showCalendar: true,
            viewOptions: viewOptionsList(),
            tab: 'tab2',
            calendarView: 'week',
            keyValue: 0,
            weekdays: [],
            selectedDate: '',
            events: [],
            calendar_start_date: '',
            calendar_end_date: '',
            timeStartPos: 0,
            titleFormatter: null,
            // [-] Calendar.

            trainings: [],
            page: 1,
            infPersonalTrainings: +new Date()
        }
    },
    watch: {
        locale() {
            if (this.locale.length > 2) {
                this.updateFormatters()
            }
        },
        shortMonthLabel() {
            this.updateFormatters()
        },
    },
    mounted() {
        this.$root.$on('calendar:next', this.onNext)
        this.$root.$on('calendar:prev', this.onPrev)
        this.$root.$on('calendar:today', this.setToday)
        
        this.updateFormatters()
        
        this.$on('transition', this.setCalendarIntervalDates())
        eventBus.$on('updateCustomer', (item) => {
            this.setCalendarIntervalDates()
        })
        eventBus.$on('update_trainings', (item) => {
            this.getItems()
        })
    },
    created: function () {
        this.setCalendarWeekdaysOrder()
        this.setCalendarIntervalDates()
    },
    beforeMount: function () {
        this.locale = getLocale()
        this.updateFormatters()
    },
    computed: {
        parent_entity_type: function () {
            return 'training'
        },
        parent_post_route: function () {
            return baseUrl + 'trainings'
        },
        panel_picture: function () {
            return '/img/personal-files/agenda.jpg'
        },
        style() {
            return {
                top: this.timeStartPos + 'px'
            }
        },
        title: {
            get() {
                if (this.titleFormatter && this.locale && this.selectedDate) {
                    const date = new Date(this.selectedDate)
                    return this.titleFormatter.format(date)
                }
                return this.getCalendarTitle()
            },
            set() {
                if (this.titleFormatter && this.locale && this.selectedDate) {
                    const date = new Date(this.selectedDate)
                    return this.titleFormatter.format(date)
                }
                return this.getCalendarTitle()
            }
        },
        intervalStart() {
            return this.intervalRange.min * (1 / this.intervalRangeStep)
        },
        intervalCount() {
            return (this.intervalRange.max - this.intervalRange.min) * (1 / this.intervalRangeStep)
        },
        post_route: function () {
            return baseUrl + 'personal/trainings'
        },
        get_route: function () {
            return baseUrl + 'personal/trainings'
        },
        get_trainings_route: function () {
            return baseUrl + 'trainings'
        },
        entity_type: function () {
            return 'personal_training'
        },
        main_title: function () {
            return this.translate('personal_training')
        },
        placeholder_find_item: function () {
            return this.translate('find_training')
        },
    },
    methods: {
        onNext() {
            this.$refs.agenda.next()
            this.title = this.getCalendarTitle(this.$refs.agenda.start)
        },
        onPrev() {
            this.$refs.agenda.prev()
            this.title = this.getCalendarTitle(this.$refs.agenda.start)
        },
        setToday() {
            this.selectedDate = this.getCurrentDate()
            // Get interval dates.
            this.setCalendarIntervalDates()
        },
        getItems: function () {
                var start_date = this.calendar_start_date
                var end_date = this.calendar_end_date
                var url = this.get_route + '/customer/' + this.customer_id + '?start_date=' + start_date + '&end_date=' + end_date
                var accessToken = this.accessToken()
                var headers = {
                    "Authorization": "Bearer " + accessToken,
                    "Content-Type": "application/json"
                }
                var mapped_events = []

                axios.get(url, {
                        headers: headers
                    })
                    .then(response => {
                        var events = response.data.items
                        events.forEach(function (training) {
                            mapped_events.push({
                                training_id: training.training_id,
                                title: training.training_name,
                                details: training.worker_name,
                                date: training.date,
                                time: '00:00',
                                end_time: '1:00',
                                duration: '60',
                                bgcolor: 'blue',
                                icon: 'fas fa-dumbbell',
                                worker_name: training.worker_name
                            })
                        })

                        this.events = mapped_events
                    })
                    .catch(function (error) {
                        // console.log(error)
                    })
        },
        showAssignTrainingPanel: function (event) {
            event = event.scope ? event.scope : event
            const panelInstance = this.$showPanel({
                component: AssignTraining,
                props: {
                    date: event.date,
                    customer_id: this.customer_id,
                    customer_name: this.customer_name,
                    customer_image_link: this.customer_image_link,
                    post_route: this.post_route,
                    parent_post_route: this.parent_post_route,
                    get_route: this.get_trainings_route,
                    item_id: this.customer_id,
                    entity_type: this.entity_type,
                    parent_entity_type: this.parent_entity_type,
                    main_title: this.main_title,
                    placeholder_find_item: this.placeholder_find_item,
                }
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.setCalendarIntervalDates()
                    }
                })
        },
        showAssignedTrainingDetailsPanel: function (id, date) {
            const Component = this.$appSettings.currentUserType === 'worker' ? AssignedTrainingDetails : AssignedTrainingDetailsCustomer
            const panelInstance = this.$showPanel({
                component: Component,
                props: {
                    training_id: id,
                    customer_id: this.customer_id,
                    customer_name: this.customer_name,
                    customer_image_link: this.customer_image_link,
                    details: true,
                    date: date
                }
            })
        },
        getCalendarTitle(given_date) {
            let date = new Date()
            let title = ''
            if (given_date) {
                date = new Date(given_date)
            }
            date.toJSON().slice(0, 10)
            title = date.toLocaleString('default', {
                month: 'long'
            }) + ' ' + date.getFullYear()

            return title
        },
        modifiedStyle: function (scope) {
            let date = scope
            if ('resource' in scope) {
                date = scope.day
            }
            if (date.disabled === true) {
                return {
                    backgroundColor: '#efefef!important'
                }
            }
            return {}
        },
        setCalendarIntervalDates: _.debounce(function (start, end) {
            // Set the start/end dates
            var refs = this.$refs
            if (refs.agenda) {
                this.calendar_start_date = refs.agenda.lastStart
                this.calendar_end_date = refs.agenda.lastEnd

                // Get the events.
                this.getItems()

                eventBus.$emit('hideTabs')
            }
        }, 300),
        onDateChanged({
            day
        }) {
            // automatically change to the day selected
            this.calendarView = 'day'
        },
        showOffset(days) {
            if (days.length === 0) return
            const val = padTime(new Date(this.getTimestamp(days[0])).getTimezoneOffset() / 60)
            if (isNaN(val)) return ''
            return 'GMT-' + val
        },
        getTimestamp(day) {
            return day.date + ' ' + padTime(day.hour) + ':' + padTime(day.minute) + ':00.000'
        },
        getEvents(dt) {
            const events = []
            for (let i = 0; i < this.events.length; ++i) {
                let added = false
                if (this.events[i].date === dt) {
                    if (this.events[i].time) {
                        if (events.length > 0) {
                            // check for overlapping times
                            const startTime = new Date(this.events[i].date + ' ' + this.events[i].time).getTime()
                            const endTime = date.addToDate(startTime, {
                                minutes: this.events[i].duration
                            }).getTime()
                            for (let j = 0; j < events.length; ++j) {
                                const startTime2 = new Date(events[j].date + ' ' + events[j].time).getTime()
                                const endTime2 = date.addToDate(startTime2, {
                                    minutes: events[j].duration
                                }).getTime()
                                if ((startTime >= startTime2 && startTime < endTime2) || (startTime2 >= startTime && startTime2 < endTime)) {
                                    events[j].side = 'left'
                                    this.events[i].side = 'right'
                                    events.push(this.events[i])
                                    added = true
                                    break
                                }
                            }
                        }
                    }
                    if (!added) {
                        this.events[i].side = void 0
                        events.push(this.events[i])
                    }
                } else if (this.events[i].days) {
                    // check for overlapping dates
                    const startDate = new Date(this.events[i].date)
                    const endDate = date.addToDate(startDate, {
                        days: this.events[i].days
                    })
                    if (date.isBetweenDates(dt, startDate, endDate)) {
                        events.push(this.events[i])
                        added = true
                    }
                }
            }
            return events
        },
        setCalendarWeekdaysOrder: function () {
            this.weekdays = [1, 2, 3, 4, 5, 6, 0]
        },
        badgeClasses(event, type) {
            const cssColor = isCssColor(event.bgcolor)
            const isHeader = type === 'header'
            return {
                [`text-white bg-${event.bgcolor}`]: !cssColor,
                'full-width': !isHeader && (!event.side || event.side === 'full'),
                'full-height': !isHeader && (!event.side || event.side === 'full'),
                'left-side': !isHeader && event.side === 'left',
                'right-side': !isHeader && event.side === 'right'
            }
        },
        badgeStyles(event, type, timeStartPos, timeDurationHeight) {
            const s = {}
            if (isCssColor(event.bgcolor)) {
                s['background-color'] = event.bgcolor
                s.color = colors.luminosity(event.bgcolor) > 0.5 ? 'black' : 'white'
            }
            if (timeStartPos) {
                s.top = timeStartPos(event.time) + 'px'
            }
            if (timeDurationHeight) {
                s.height = timeDurationHeight(event.duration) + 'px'
            }
            return s
        },
        setView(view) {
            this.calendarView = view.value
            // Get appointment locations for the selected service.
            this.setCalendarIntervalDates()
        },
        getCurrentDate() {
            var currentDateWithFormat = new Date().toJSON().slice(0, 10);
            return currentDateWithFormat
        },
        updateFormatters() {
            try {
                this.dateFormatter = new Intl.DateTimeFormat(this.locale || void 0, {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: false,
                    timeZone: 'UTC'
                })
                this.titleFormatter = new Intl.DateTimeFormat(this.locale || void 0, {
                    month: this.shortMonthLabel ? 'short' : 'long',
                    year: 'numeric',
                    timeZone: 'UTC'
                })
            } catch (e) {
                // console.error('Intl.DateTimeFormat not supported')
                this.dateFormatter = void 0
                this.titleFormatter = void 0
            }
        },
    },
}
// Calendar View options list.
function viewOptionsList() {
    return [{
            label: 'Day',
            value: 'day'
        },
        {
            label: '3 Days',
            value: '3day'
        },
        {
            label: 'Week',
            value: 'week'
        },
        {
            label: 'Month',
            value: 'month'
        }
    ]
}
</script>

<style lang="scss" scoped>
.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: .5rem 0;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }
}

.trainings-timeline {
    margin-bottom: 2px !important;
}
</style>

<style lang="sass">
.agenda .q-calendar-daily__day-interval:hover
    background-color: rgba(0,0,255,.1)
.agenda .q-calendar-weekly__workweek:hover
    background-color: rgba(0,0,255,.1)
.agenda .q-calendar-weekly__day:hover
    background-color: rgba(0,0,255,.1)
.agenda .q-calendar-weekly__head-weekday:hover
    background-color: rgba(0,0,255,.1)

.agenda .q-calendar-weekly__day
    display: flex
    flex-direction: column
    padding: 0!important

.agenda .q-calendar-weekly__day.q-future-day[style*="background-color: rgb(239, 239, 239) !important;"]
    pointer-events: none
    cursor: not-allowed

.agenda.calendar-container
    position: relative
    width: 100%
    max-width: 100%
    overflow-x: hidden

.agenda .my-event
    width: 100%
    height: 100%
    position: absolute
    font-size: 12px
    border-radius: 0
    cursor: pointer
    justify-content: flex-start
    align-items: center!important
    overflow-y: auto

.agenda .full-width
    left: 0
    width: 100%

.agenda .left-side
    left: 0
    width: 49.75%

.agenda .right-side
    left: 50.25%
    width: 49.75%

.agenda .horizontal-list
    width: auto
    overflow-x: auto
    flex-direction: row
    flex-flow: nowrap
    .list-item
        .q-card
            transition: all .2s ease-in-out
            box-shadow: 0 3px 6px rgba(0, 0, 0, .16)
            border-left: 2px solid rgba(0, 0, 0, .25)
            border-right: 2px solid rgba(0, 0, 0, .25)
            border-top: 2px solid rgba(0, 0, 0, .25)
            border-bottom: 2px solid rgba(0, 0, 0, .25)
            &:hover
                box-shadow: 0 3px 6px rgba(0, 0, 0, .36)
            &.selected-horizontal-card
                border-left: 2px solid rgba(0, 0, 0, .5)
                border-right: 2px solid rgba(0, 0, 0, .5)
                border-top: 2px solid rgba(0, 0, 0, .5)
                border-bottom: 2px solid rgba(0, 0, 0, .5)

.agenda .no-image
    background: rgba(0, 0, 0, .1)
    .q-icon
        color: rgba(0, 0, 0, .15)
        font-size: 2.5rem
        line-height: 2.5rem

.agenda .hidden-calendar 
    opacity: 0
    pointer-events: none
    position: fixed

.agenda .q-calendar-daily__head
    margin-right: 0!important
.agenda .q-calendar-daily__scroll-area
    overflow: overlay
.agenda .q-calendar-daily__scroll-area::-webkit-scrollbar
    width: 10px
.agenda .q-calendar-daily__scroll-area::-webkit-scrollbar 
    *
        background: rgba(0, 0, 0, 0)
.agenda .q-calendar-daily__scroll-area::-webkit-scrollbar-thumb
    background: var(--q-color-light)!important
.agenda .q-calendar-daily__scroll-area::-webkit-scrollbar-thumb:hover
    background: var(--q-color-grey)!important
.agenda.calendar-container:not(.calendar-month)
    height: auto
</style>

<style lang="scss">
    .agenda .q-calendar-daily__intervals-head,
    .agenda .q-calendar-daily__intervals-body {
        width: 0!important;
    }
    .agenda .q-calendar-weekly__day--content {
        display: flex;
        flex-direction: column;
        height: 100%!important;
        max-height: 100%!important;
        justify-items: center;
    }
        
    .agenda .q-calendar-weekly__day--content .q-badge {
        display: flex!important;
        flex-direction: column;
        height: 100%!important;
        max-height: 100%!important;
        justify-content: center;
    }

    .agenda.calendar-container.calendar-month .bg-blue {
        background-color: rgba(32, 168, 216, .25) !important;
        color: rgba(32, 168, 216, 1) !important;
        border-radius: 0!important;
    }
  </style>