<template>
<b-row class="full-width">
    <b-col v-if="$q.screen.lt.sm" sm="12" md="2" class="bg-light p-3 p-md-4">
        <panel-picture :src="panel_picture" />
    </b-col>
    <b-col sm="12" class="p-3 p-md-4 bg-white">
        <q-item class="full-width p-0 mb-2">
            <q-item-section>
                <div class="text-h6">{{ translate('personal_objectives') }}</div>
            </q-item-section>
            <q-item-section v-if="!type_customer" side>
                <q-btn v-if="!$q.screen.lt.sm" no-caps color="primary" type="button" @click="showAssignObjectivesPanel">
                    <i class="fas fa-plus mr-1"></i> {{ translate('personal_objectives') }}
                </q-btn>
                <q-btn v-if="$q.screen.lt.sm" flat round class="full-width" no-caps color="primary" type="button" @click="showAssignObjectivesPanel">
                    <i class="fas fa-plus"></i>
                </q-btn>
            </q-item-section>
        </q-item>
        <q-separator />
        <div v-if="show_search === true" :class="this.$q.screen.lt.sm ? '' : 'bg-light p-4'">
            <search-wrapper-card :fullwidth_textinput="true" class="timeline-search shadow-2" :no_filters="true" form_type="obs">
                <q-input slot="search_input" bottom-slots @input="isTypingPersonalObjective = true" v-model="searchPersonalObjective" id="filternamepersonalobjective" class="search-input" type="text" :placeholder="translate('find_personal_objective')" :dense="true">
                    <template v-slot:prepend>
                        <q-icon name="search" />
                    </template>
                </q-input>
            </search-wrapper-card>
        </div>
        <div class="mt-4 text-bold mb-4">
            <i class="text-blue fas fa-history mr-2"></i>{{ translate('assigned_objectives') }}
        </div>
        <template class="text-left align-items-start">
            <q-timeline color="blue" class="objectives-timeline list_wrapper-personal_objectives">
                <q-timeline-entry v-for="(objective,i) in objectives" :key="'objective-' + i + '-' + objective.id" :color="timelineColor(objective.objective_status)" :title="objective.objective_name" :subtitle="prettyDate(objective.created_at)">
                    <div>
                        <q-btn @click.prevent="showPersonalObjectiveSettingsPanel(objective, prettyDate(objective.created_at))" flat color="blue" icon="event" size="sm" dense no-caps :label="translate('details')" />
                    </div>
                </q-timeline-entry>
            </q-timeline>
            <infinite-loading :identifier="infPersonalObjectives" slot="append" @infinite="getPersonalObjectives" />
        </template>
        <template v-if="!objectives || objectives.length === 0">
            <q-item class="list-group-item-accent-dark bg-light text-dark mt-2">
                <q-item-section>
                    <div>
                        {{ translate(no_results_message) }}<br />
                        <q-btn v-if="!type_customer" no-caps color="primary" type="button" @click="showAssignObjectivesPanel">
                            <i class="fas fa-plus mr-1"></i> {{ translate('personal_objectives') }}
                        </q-btn>
                    </div>
                </q-item-section>
            </q-item>
        </template>
    </b-col>
</b-row>
</template>

<script>
import {
    eventBus
} from "../../../main"
import PanelPicture from '../../template-parts/PanelPicture'
import AssignObjectives from '../forms/AssignObjectives'
import PersonalObjectiveSettings from '../forms/PersonalObjectiveSettings'
import SearchWrapperCard from '../../template-parts/SearchWrapperCard'

export default {
    name: 'Objectives',
    props: ['customer_id', 'customer_name'],
    components: {
        'panel-picture': PanelPicture,
        'search-wrapper-card': SearchWrapperCard,
    },
    data: function () {
        return {
            message_customer: 'no_assigned_objectives_yet',
            message_worker: 'no_assigned_objectives_yet_use_the_button_below_to_start_adding',
            show_search: true,
            searchPersonalObjective: '',
            isTypingPersonalObjective: false,
            type_customer: this.$appSettings.currentUserType() === 'customer' ? true : false,
            objectives: [],
            page: 1,
            state: {},
            infPersonalObjectives: +new Date(),
            item_active: 'active',
            item_inactive: '',
        }
    },
    computed:  {
        no_results_message: {
            // getter
            get: function () {
                return this.type_customer === true ? this.message_customer : this.message_worker
            },
            // setter
            set: function (newValue) {
                if (this.type_customer === true) {
                     this.message_customer = newValue
                } else {
                    this.message_worker = newValue
                }
            }
        },
        panel_picture: function () {
            return '/img/personal-files/obiective_02.jpg'
        },
        post_route: function () {
            return baseUrl + 'personal/objectives'
        },
        parent_post_route: function () {
            return baseUrl + 'objectives'
        },
        get_route: function () {
            return baseUrl + 'personal/objectives'
        },
        get_objectives_route: function () {
            return baseUrl + 'objectives'
        },
        delete_route: function () {
            return baseUrl + 'personal/objectives'
        },
        entity_type: function () {
            return 'personal_objective'
        },
        parent_entity_type: function () {
            return 'objective'
        },
        placeholder_find_item: function () {
            return this.translate('find_objective')
        },
    },
    watch: {
        searchPersonalObjective: _.debounce(function () {
            this.isTypingPersonalObjective = false
        }, 200),
        isTypingPersonalObjective: function (value) {
            if (!value) {
                this.resetMainList()

                if (!this.objectives || this.objectives.length === 0) {
                    this.no_results_message = 'no_objectives_found_on_search'
                }
            }
        }
    },
    created: function () {
        this.$on('transition', this.resetMainList())
        eventBus.$on('update_all', () => {
             this.resetMainList()
        })
        eventBus.$on('updateCustomer', (item) => {
            this.resetMainList()
        })
    },
    methods: {
        timelineColor: function (status) {
            var color = 'green'
            if (status === 'pending') {
                color = 'blue'
            }
            if (status === 'failed') {
                color = 'red'
            }
            return color
        },
        resetMainList: function () {
            this.page = 1
            this.objectives = []
            this.infPersonalObjectives++
            eventBus.$emit('hideTabs')
        },
        getPersonalObjectives: function ($state) {
            var url = baseUrl + 'personal/objectives/customer/' + this.customer_id + '?page=' + this.page + '&items_per_page=' + this.$items_per_page + 
            (this.searchPersonalObjective ? '&search=' + this.searchPersonalObjective : '')
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            this.state = $state
            // To solve latency with server, push the items into a temporary scoped array and then into the main array.
            let objectives = this.objectives
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (response.data.items && response.data.items.length > 0) {
                        objectives.push(...response.data.items)
                    } 
                    if (response.data.next_page === true) {
                        this.page++
                        $state.loaded()
                    } else {
                        // To solve latency with server, push the items into a temporary scoped array and then into the main array.
                        this.objectives = objectives
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        showAssignObjectivesPanel: function (objective) {
            const panelInstance = this.$showPanel({
                component: AssignObjectives,
                props: {
                    objective: objective,
                    customer_id: this.customer_id,
                    post_route: this.post_route,
                    parent_post_route: this.parent_post_route,
                    get_route: this.get_objectives_route,
                    entity_type: this.entity_type,
                    parent_entity_type: this.parent_entity_type,
                    customer_name: this.customer_name,
                    placeholder_find_item: this.placeholder_find_item,
                }
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.resetMainList()
                    }
                })
        },
        showPersonalObjectiveSettingsPanel: function (objective, date) {
            const panelInstance = this.$showPanel({
                component: PersonalObjectiveSettings,
                props: {
                    objective: objective,
                    customer_id: this.customer_id,
                    customer_name: this.customer_name,
                    entity_type: this.entity_type,
                    get_route: this.get_route,
                    date: date
                }
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.$emit('closePanel')
                    }
                })
        },
        activeStatus: _.debounce(function () {
            if (this.item_active === 'active') {
                this.item_active = ''
            } else {
                this.item_active = 'active'
            }
            this.resetMainList()
        }, 200),
        inactiveStatus: _.debounce(function () {
            if (this.item_inactive === 'inactive') {
                this.item_inactive = ''
            } else {
                this.item_inactive = 'inactive'
            }
            this.resetMainList()
        }, 200),
    },
}
</script>
