<template>
<div class="fit">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless>
        <b-tab class="p-0">
            <template slot="title">
                <i class="mt-1 mb-2 fas fa-trash"></i>
            </template>
            <b-list-group class="list-group-accent">
                <q-separator />
                <b-list-group-item class="list-group-item-accent-danger bg-light font-weight-bold font-small">
                    <q-icon size="1rem" color="red" name="delete" />&nbsp;
                    {{ panel_title }}
                </b-list-group-item>
                <q-separator />
                <b-list-group-item class="list-group-item-accent-info">
                    <q-item class="p-0">
                        <q-item-section>
                            <q-item-label line="1">{{ panel_message }}</q-item-label>
                        </q-item-section>
                    </q-item>
                </b-list-group-item>
                <q-separator />
                <b-list-group-item class="list-group-item-accent-info ">
                    <q-item class="p-0 full-width">
                        <q-item-section side>
                            <q-btn no-caps @click="deleteItem" type="submit" color="danger">
                                <q-icon class="mr-1" size="1rem" name="check" /> {{ translate('yes') }}
                            </q-btn>
                        </q-item-section>
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn no-caps @click.prevent="closePanel" type="button" color="secondary" class="text-dark">
                                <q-icon class="mr-1" size="1rem" name="cancel" /> {{ translate('no') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </b-list-group-item>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import {eventBus} from "../../../main"

export default {
    name: 'DeleteItem',
    props: ['item_id', 'delete_route', 'panel_title', 'panel_message', 'entity_type'],
    data() {
        return {
            msg: null,
        }
    },
    methods: {
        deleteItem: function () {
            var url = this.delete_route + '/' + this.item_id
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }

            axios.delete(url, {
                    headers: headers
                })
                .then(response => {
                    this.msg = response.data.msg
                    var entity_type = this.entity_type ? this.entity_type + '_' : ''
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = entity_type + this.msg + key
                    var translated_message = this.translate(message)

                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        this.$emit('closePanel', {
                            status: true
                        })
                        eventBus.$emit('update_all')
                    } 
                    else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        closePanel: function () {
            this.$emit('closePanel')
        }
    }
}
</script>
