<template>
<div class="fit">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless>
        <b-tab class="p-0">
            <template slot="title">
                <q-icon name="info" />
            </template>
            <b-list-group class="list-group-accent">
                <b-list-group-item class="list-group-item-accent-secondary bg-light font-weight-bold text-uppercase">
                    <q-icon name="add" />&nbsp;
                    {{ translate('edit_personal_data') }}
                </b-list-group-item>
                <b-form @submit.prevent="registerCustomer">
                    <b-list-group-item class="list-group-item-accent-info bg-custom-lightblue font-weight-bold">
                        {{ translate('first_name') }}*
                    </b-list-group-item>
                    <b-list-group-item class="list-group-item-accent-info">
                        <b-form-input id="first_name" type="text" name="first_name" v-model="customer.first_name" :placeholder="translate('first_name')" />
                    </b-list-group-item>
                    <b-list-group-item class="list-group-item-accent-info bg-custom-lightblue font-weight-bold">
                        {{ translate('last_name') }}*
                    </b-list-group-item>
                    <b-list-group-item class="list-group-item-accent-info">
                        <b-form-input id="last_name" type="text" name="last_name" v-model="customer.last_name" :placeholder="translate('last_name')" />
                    </b-list-group-item>
                    <b-list-group-item class="list-group-item-accent-info bg-custom-lightblue font-weight-bold">
                        {{ translate('gender') }}*
                    </b-list-group-item>
                    <b-list-group-item class="list-group-item-accent-info pt-3 pb-2 pl-0 pr-0">
                        <q-select square dense outlined color="primary" v-model="customer.gender" :options="gender" :label="translate('select_gender')" />
                    </b-list-group-item>
                    <b-list-group-item class="list-group-item-accent-info bg-custom-lightblue font-weight-bold">
                        {{ translate('birth_date') }}*
                    </b-list-group-item>
                    <b-list-group-item class="list-group-item-accent-info pt-3 pb-2 pl-0 pr-0">
                        <template>
                            <q-input square dense color="primary" class="full-width" id="birth_date" outlined v-model="customer.birth_date">
                                <template v-slot:append>
                                    <q-icon name="event" class="cursor-pointer">
                                        <q-popup-proxy transition-show="scale" transition-hide="scale">
                                            <q-date first-day-of-week="1" v-model="customer.birth_date" mask="YYYY-MM-DD" />
                                        </q-popup-proxy>
                                    </q-icon>
                                </template>
                            </q-input>
                        </template>
                    </b-list-group-item>
                    <b-list-group-item class="list-group-item-accent-info bg-custom-lightblue font-weight-bold">
                        {{ translate('phone_number') }}*
                    </b-list-group-item>
                    <b-list-group-item class="list-group-item-accent-info">
                        <b-form-input id="phone" type="text" name="phone" v-model="customer.phone_number" :placeholder="translate('phone_number')" />
                    </b-list-group-item>
                    <b-list-group-item class="list-group-item-accent-info bg-custom-lightblue font-weight-bold">
                        {{ translate('email') }}*
                    </b-list-group-item>
                    <b-list-group-item class="list-group-item-accent-info">
                        <b-form-input disabled id="email" type="email" name="email" v-model="customer.email" :placeholder="translate('email')" />
                    </b-list-group-item>
                    <q-separator />
                    <b-list-group-item class="list-group-item-accent-info">
                        <q-item class="p-0 full-width">
                            <q-item-section side>
                                <q-btn type="submit" no-caps color="info">
                                    <q-icon size="1rem" name="check" class="mr-1" /> {{ translate('save') }}
                                </q-btn>
                            </q-item-section>
                            <q-item-section></q-item-section>
                            <q-item-section side>
                                <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                    <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('cancel') }}
                                </q-btn>
                            </q-item-section>
                        </q-item>
                    </b-list-group-item>
                </b-form>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
export default {
    name: 'EditPersonalData',
    props: ['customer_id'],
    data: function() {
        return {
            type_customer: this.$appSettings.currentUserType() === 'customer' ? true : false,
            customer: {
                first_name: null,
                last_name: null,
                gender: null,
                birth_date: null,
                phone: null,
                email: null,
            },
        }
    },
    created() {
        this.getCustomer()
    },
    computed: {
        gender: function () {
            return [{
                    value: 'f',
                    label: this.translate('gender_female')
                },
                {
                    value: 'm',
                    label: this.translate('gender_male')
                },
            ]
        },
    },
    methods: {
        getCustomer: function () {
            var url = baseUrl + 'customers/'
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'multipart/form-data'
            }
            axios.get(url + this.customer_id, {
                    headers: headers
                })
                .then(response => {
                    if (response.data.status) {
                        this.customer = response.data.item
                        var gender_label = this.gender.filter(gender => { return response.data.item.gender === gender.value ? gender.label : ''})
                        gender_label = gender_label[0].label
                        this.customer.gender = {'value': response.data.item.gender, 'label': gender_label}
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        registerCustomer: function () {
            var data = {
                'first_name': this.customer.first_name,
                'last_name': this.customer.last_name,
                'gender': this.customer.gender.value,
                'birth_date': this.customer.birth_date,
                'phone_number': this.customer.phone_number,
                'email': this.customer.email,
            }

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken()
            }

            axios.put(baseUrl + 'customers/' + this.customer_id, data, {
                    headers: headers
                })
                .then(response => {
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = 'edit_personal_data_' + response.data.msg + key
                    var translated_message = this.translate(message)

                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        this.$emit('closePanel', {
                            response: this.customer
                        })
                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(error => {
                    var msg = error.response.data.msg
                    if (msg != undefined) {
                        this.$toasted.error('An error has occured', {
                            duration: this.$toasted_duration
                        })
                    }

                })
        },
        closePanel: function () {
            this.$emit('closePanel')
        }
    }
}
</script>
