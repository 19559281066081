<template>
<div>
    <template>
        <div v-if="!items || items.length == 0">
            <div class="text-muted">{{ translate('no_results') }}</div>
        </div>
        <div v-else>
            <div class="text-muted">{{ translate('results_found') }}: {{ items.length }}</div>
        </div>
        <q-input class="search-input mb-0" @input="isTypingItem = true" v-model="searchItem" id="filternameitem" type="text" :placeholder="placeholder_find_item ? translate(placeholder_find_item) : ''" :dense="true">
            <template v-slot:prepend>
                <q-icon name="search" />
            </template>
            <template v-if="add_button !== null" v-slot:append>
                <q-btn :size="$q.screen.lt.sm ? '0.65rem' : null" type="button" @click.stop="showAddItemPanel" flat dense color="dark" no-caps :label="translate('new_' + parent_entity_type)" icon="add" />
            </template>
        </q-input>
        <div class="items-container" :class="'list_wraper-' + entity_type + '-' + item_id">
            <q-item tag="label" class="list-item full-width" clickable v-ripple v-for="(item,i) in items" :key="'list-item-' + i + '-' + item.id">
                <q-item-section>
                    <q-item-label>{{ item.name }}</q-item-label>
                </q-item-section>
                <q-item-section side>
                    <q-btn icon="add" @click.prevent="registerItem(item)" dense flat color="primary" />
                </q-item-section>
            </q-item>
        </div>
        <infinite-loading @infinite="getItems" ref="infiniteLoading" :identifier="'include-items-' + entity_type + '-' + item_id + '-' +infItems" slot="append" />
    </template>
</div>
</template>

<script>
import {
    eventBus
} from "../../../main"

import InfiniteLoading from 'vue-infinite-loading'
import AddItem from '../../data-libraries/template-parts/forms/AddItem'

export default {
    name: 'IncludeItem',
    props: ['post_route', 'get_route', 'item_id', 'placeholder_find_item', 'entity_type', 'parent_entity_type', 'parent_post_route', 'add_button', 'field_unit'],
    watch: {
        searchItem: _.debounce(function () {
            this.isTypingItem = false
        }, 200),
        isTypingItem: function (value) {
            if (!value) {
                this.resetMainList()
            }
        }
    },
    components: {
        'infinite-loading': InfiniteLoading,
    },
    data: function () {
        return {
            items: [],
            page: 1,
            state: {},
            infItems: +new Date(),
            isTypingItem: false,
            searchItem: '',
            msg: '',
            item: {
                note: null
            }
        }
    },
    created() {
        // eventBus.$on('update_included_items', () => {
        //     this.resetMainList()
        // })
    },
    computed: {
        add_button_text: function () {
            return this.translate('new_' + this.parent_entity_type)
        },
        add_item_title: function () {
            return this.translate('add_' + this.parent_entity_type)
        },
    },
    methods: {
        showAddItemPanel() {
            const panelInstance = this.$showPanel({
                component: AddItem,
                props: {
                    add_item_title: this.add_item_title,
                    post_route: this.parent_post_route,
                    entity_type: this.parent_entity_type,
                    field_unit: this.field_unit,
                    field_description: this.field_description,
                }
            })

            panelInstance.promise.then(result => {
                if (result && result.status) {
                    eventBus.$emit('update_included_items')
                }
            })
        },
        resetMainList: function () {
            this.page = 1
            this.items = []
            this.infItems++
        },
        post_data: function (item) {
            var data = {
                "customer_id": this.item_id,
                "equipment_id": item.id,
            }
            return data
        },
        getItems: function ($state) {
            var entity_type = this.entity_type ? this.entity_type + 's' : ''
            var route = this.get_route  + '/' + this.item_id
            var url = route + '?page=' + this.page + '&items_per_page=' + this.$items_per_page + 
            (this.searchItem ? '&search=' + this.searchItem : '')
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            this.state = $state
            // To solve latency with server, push the items into a temporary scoped array and then into the main array.
            let items = this.items
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (response.data.items && response.data.items.length > 0) {
                        items.push(...response.data.items)
                    } 
                    if (response.data.next_page === true) {
                        this.page++
                        $state.loaded()
                    } else {
                        // To solve latency with server, push the items into a temporary scoped array and then into the main array.
                        this.items = items
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        registerItem: function (item) {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }
            
            var data = this.post_data(item)
            var url = this.post_route
            
            axios.post(url, data, {
                    headers: headers
                })
                .then(response => {
                    this.msg = response.data.msg
                    var entity_type = this.entity_type ? this.entity_type + '_' : ''
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = entity_type + this.msg + key
                    var translated_message = this.translate(message)

                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        
                        // Visually remove the added item from the list of available items.
                        this.items.splice(this.items.indexOf(item), 1)
                        // this.$emit('update_list')
                        eventBus.$emit('update_included_items')
                    } 
                    else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(error => {
                    if (error != undefined) {
                        var msg = error.response.data.msg
                        this.msg = msg
                        this.$toasted.error(msg, {
                            duration: 3000
                        })
                    }

                })
        },
    },
}
</script>

<style lang="scss" scoped>
.items-container {
    max-height: 30vh;
    overflow-y: auto;
}

.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: .5rem 0;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }
}
body .search-input {
    width: calc(100% + 30px);
}
</style>
