<template>
<b-row class="full-width">
    <b-col v-if="$q.screen.lt.sm" sm="12" md="2" class="bg-light p-3 p-md-4">
        <panel-picture :src="panel_picture" />
    </b-col>
    <b-col sm="12" class="p-3 p-md-4 bg-white">
        <q-item class="full-width p-0 mb-2">
            <q-item-section>
                <div class="text-h6">{{ translate('measurements') }}</div>
            </q-item-section>
            <q-item-section v-if="!type_customer" side>
                <q-btn v-if="!$q.screen.lt.sm" no-caps color="primary" type="button" @click="showAddPersonalMeasurementPanel">
                    <i class="fas fa-plus mr-1"></i> {{ translate('measurements') }}
                </q-btn>
                <q-btn v-if="$q.screen.lt.sm" flat round class="full-width" no-caps color="primary" type="button" @click="showAddPersonalMeasurementPanel">
                    <i class="fas fa-plus"></i>
                </q-btn>
            </q-item-section>
        </q-item>
        <q-separator />
        <div v-if="show_search === true" :class="this.$q.screen.lt.sm ? '' : 'bg-light p-4'">
            <search-wrapper-card :no_filters="true" :fullwidth_textinput="true" class="timeline-search shadow-2" form_type="measurements">
                <q-input slot="search_input" bottom-slots @input="isTypingItem = true" v-model="searchItem" id="filternamepersonalmeasurement" class="search-input" type="text" :placeholder="translate('find_personal_measurement')" :dense="true">
                    <template v-slot:prepend>
                        <q-icon name="search" />
                    </template>
                </q-input>
            </search-wrapper-card>
        </div>
        <div class="mt-4 text-bold mb-4">
            <i class="text-blue fas fa-history mr-2"></i>{{ translate('measurements_history') }}
        </div>
        <div v-if="measurements || measurements.length > 0" class="d-flex justify-content-left">
            <q-timeline color="blue" class="measurements-timeline list_wrapper-measurements col-md-6">
                <q-timeline-entry v-for="(measurement,i) in measurements" :key="'measurement-' + i + '-' + measurement.id" :title="measurement.measurement_name" :subtitle="prettyDate(measurement.created_at)">
                    <span class="text-muted measurement-value shadow-2">{{ measurement.measurement_value }} {{ measurement.measurement_unit }}</span>
                    <div>
                        <q-btn @click.prevent="showPersonalMeasurementSettingsPanel(measurement, prettyDate(measurement.created_at))" flat color="blue" icon="event" size="sm" dense no-caps :label="translate('details')" />
                    </div>
                </q-timeline-entry>
            </q-timeline>
            <infinite-loading :identifier="infPersonalMeasurements" slot="append" @infinite="getPersonalMeasurements" />
        </div>
        <template v-if="!measurements || measurements.length === 0">
            <q-item class="list-group-item-accent-dark bg-light text-dark mt-2">
                <q-item-section>
                    <div v-if="!type_customer">
                        {{ translate(no_results_message) }}<br />
                        <q-btn no-caps color="primary" type="button" @click="showAddPersonalMeasurementPanel">
                            <i class="fas fa-plus mr-1"></i> {{ translate('measurements') }}
                        </q-btn>
                    </div>
                    <div v-if="type_customer">
                        {{ translate('no_results') }}
                    </div>
                </q-item-section>
            </q-item>
        </template>
    </b-col>
</b-row>
</template>

<script>
import {
    eventBus
} from "../../../main"
import PanelPicture from '../../template-parts/PanelPicture'
import AddPersonalMeasurement from '../forms/AddPersonalMeasurement'
import PersonalMeasurementSettings from '../forms/PersonalMeasurementSettings'
import SearchWrapperCard from '../../template-parts/SearchWrapperCard'

export default {
    name: 'Measurements',
    props: ['customer_id', 'customer_name'],
    components: {
        'panel-picture': PanelPicture,
        'search-wrapper-card': SearchWrapperCard,
    },
    data: function () {
        return {
            no_results_message: 'no_assigned_measurements_yet_use_the_button_below_to_start_adding',
            type_customer: this.$appSettings.currentUserType() === 'customer' ? true : false,
            show_search: true,
            searchItem: '',
            isTypingItem: false,
            measurements: [],
            page: 1,
            state: {},
            infPersonalMeasurements: +new Date(),
        }
    },
    computed:  {
        layout: function () {
            return this.$q.screen.lt.sm ? 'dense' : (this.$q.screen.lt.md ? 'comfortable' : 'loose')
        },
        panel_picture: function () {
            return '/img/personal-files/masuratori.jpg'
        },
        post_route: function () {
            return baseUrl + 'personal/measurements'
        },
        get_route: function () {
            return baseUrl + 'personal/measurements'
        },
        get_measurements_route: function () {
            return baseUrl + 'measurements'
        },
        delete_route: function () {
            return baseUrl + 'personal/measurements'
        },
        entity_type: function () {
            return 'personal_measurement'
        },
        placeholder_find_item: function () {
            return this.translate('find_measurement')
        },
    },
    watch: {
        searchItem: _.debounce(function () {
            this.isTypingItem = false
        }, 200),
        isTypingItem: function (value) {
            if (!value) {
                this.resetMainList()

                if (!this.measurements || this.measurements.length === 0) {
                    this.no_results_message = 'no_measurements_found_on_search'
                }
            }
        }
    },
    created: function () {
        this.$on('transition', this.resetMainList())
        eventBus.$on('update_all', () => {
             this.resetMainList()
        })
        eventBus.$on('updateCustomer', (item) => {
            this.resetMainList()
        })
    },
    methods: {
        timelineColor: function (status) {
            var color = 'primary'
            
            if (status === false) {
                color = 'grey'
            }
            return color
        },
        resetMainList: function () {
            this.page = 1
            this.measurements = []
            this.infPersonalMeasurements++
            eventBus.$emit('hideTabs')
        },
        getPersonalMeasurements: function ($state) {
            var url = baseUrl + 'personal/measurements/customer/' + this.customer_id + '?page=' + this.page + 
            (this.$items_per_page ? '&items_per_page=' + this.$items_per_page : '') + 
            (this.searchItem ? '&search=' + this.searchItem : '')
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            this.state = $state
            // To solve latency with server, push the items into a temporary scoped array and then into the main array.
            let measurements = this.measurements
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (response.data.items && response.data.items.length > 0) {
                        measurements.push(...response.data.items)
                    }
                    if (response.data.next_page === true) {
                        this.page++
                        $state.loaded()
                    } else {
                        // To solve latency with server, push the items into a temporary scoped array and then into the main array.
                        this.measurements = measurements
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        showAddPersonalMeasurementPanel: function (measurement) {
            const panelInstance = this.$showPanel({
                component: AddPersonalMeasurement,
                props: {
                    measurement: measurement,
                    customer_id: this.customer_id,
                    post_route: this.post_route,
                    get_route: this.get_measurements_route,
                    entity_type: this.entity_type,
                    customer_name: this.customer_name,
                    placeholder_find_item: this.placeholder_find_item,
                }
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.resetMainList()
                    }
                })
        },
        showPersonalMeasurementSettingsPanel: function (measurement, date) {
            const panelInstance = this.$showPanel({
                component: PersonalMeasurementSettings,
                props: {
                    measurement: measurement,
                    customer_id: this.customer_id,
                    customer_name: this.customer_name,
                    entity_type: this.entity_type,
                    get_route: this.get_route, 
                    date: date
                }
            })
        },
    },
}
</script>

<style lang="scss">
.measurements-timeline {
    h6 {
        margin-bottom: 0;
    }
    .measurement-value {
        display: inline-block;
        margin-top: 5px;
        margin-bottom: 7px;
        font-weight: 700;
        padding: 5px 10px;
        line-height: 1;        
        background-color: #fff;
        background-clip: border-box;
        border: 1px solid #c8ced3;
        border-radius: 0.25rem;
    }
}
</style>