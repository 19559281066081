<template>
<div>
    <div class="mt-4 mb-2 text-bold">
        <i class="text-indigo fas fa-history mr-2"></i>{{ translate('absences') }}
    </div>
    <template>
        <q-timeline color="light-blue-9" class="list_wrapper-absences trainings-timeline">
            <q-timeline-entry :color="timelineColor(absence.status)" v-for="(absence,i) in absences" :key="'absence-' + i + '-' + absence.id">
                <template slot="title">
                    <q-item @click="absence.show=!absence.show" clickable class="m-0 p-0 cursor-pointer col-xs-12 col-md-5" style="min-height: 0;" :class="$q.screen.xs ? 'small' : ''">
                        <q-item-section side class="text-light-blue-9 bg-lightrey-2 col-6 col-md-8 p-0 arrow-right">
                            <div class="p-1 pl-2 pr-2 font-weight-normal">
                                <i :class="absence.show ? 'fas fa-angle-up' : 'fas fa-angle-down'" class="mr-1" size="sm"></i>
                                {{ prettyDate(absence.date) }}
                            </div>
                        </q-item-section>
                        <q-item-section class="text-center bg-lightrey-2 p-0">
                            <div class="text-black p-1 pl-2 pr-2">{{ absence.service_name ? absence.service_name : '' }}</div>
                        </q-item-section>
                    </q-item>
                </template>
                <template v-if="absence.show===true">
                    <q-card flat class="cursor-pointer col-xs-12 col-md-5 m-0 p-0" :class="$q.screen.xs ? 'small' : ''">
                        <q-card-section class="p-0 pb-1">
                            <div class="row align-items-center">
                                <div class="col-12 col-sm-6 col-md-4">
                                    <small class="text-muted">{{ translate('marked_at') }}</small><br />
                                    {{ prettyDate(absence.marked_at) }}
                                </div>
                                <div class="col-12 col-sm-6 col-md-4">
                                    <small class="text-muted">{{ translate('marked_by') }}</small><br />
                                    <q-chip color="light" class="pl-0">
                                        <q-avatar v-if="absence.worker_image_link">
                                            <img :src="absence.worker_image_link">
                                        </q-avatar>
                                        <i v-else class="fas fa-user text-muted"></i>
                                        {{ absence.worker_name }}
                                    </q-chip>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4" v-if="absence.status">
                                    <small class="text-muted">{{ translate('absence_status') }}</small><br />
                                    <span class="cursor-default text-warning" v-if="absence.status === 'absence_warned'">
                                        <q-icon class="mr-1" size="1rem" name="fas fa-exclamation" /> <span>{{ translate('absence_warned') }}</span>
                                    </span>
                                    <span class="cursor-default text-danger" v-if="absence.status === 'absence_penalized'">
                                        <q-icon class="mr-1" size="1rem" name="fas fa-exclamation" /> <span>{{ translate('absence_penalized') }}</span>
                                    </span>
                                </div>
                            </div>
                        </q-card-section>
                    </q-card>
                </template>
            </q-timeline-entry>
        </q-timeline>
        <template v-if="!absences || absences.length === 0">
            {{ translate('no_results') }}
        </template>
        <infinite-loading :identifier="infAbsences" slot="append" @infinite="getItems" />
    </template>
</div>
</template>

<script>
import {
    eventBus
} from "../../../main"

export default {
    name: 'Absences',
    props: ['customer_id'],
    data: function () {
        return {
            type_customer: this.$appSettings.currentUserType() === 'customer' ? true : false,
            absences: [],
            page: 1,
            state: {},
            infAbsences: +new Date()
        }
    },
    mounted: function () {
        this.$on('transition', this.getItems)
        eventBus.$emit('hideTabs')
        eventBus.$on('update_included_items', () => {
            this.resetMainList()
        })
        eventBus.$on('updateCustomer', (item) => {
            this.resetMainList()
        })
    },
    created: function () {},
    computed: {
        get_route: function () {
            return baseUrl + 'absences'
        },
        entity_type: function () {
            return 'absence'
        },
    },
    methods: {
        timelineColor: function (status) {
            let color = 'light-blue-9'
            if (status === 'absence_warned') {
                color = 'warning'
            }
            if (status === 'absence_penalized') {
                color = 'danger'
            }
            return color
        },
        secondsToFormattedTime: function (seconds) {
            const formatted_time = new Date(seconds * 1000).toISOString().substr(11, 8)
            return formatted_time
        },
        resetMainList: function () {
            this.page = 1
            this.absences = []
            this.infAbsences++
        },
        getItems: function ($state) {
            var url = this.get_route + '?customer_id=' + this.customer_id + '&page=' + this.page + '&items_per_page=' + this.$items_per_page
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            this.state = $state
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (response.data.items && response.data.items.length > 0) {
                        this.absences.push(...response.data.items.map(entity => {
                            let array = entity
                            array['show'] = false
                            array['worker_image_link'] = entity.worker_image_link ? baseUrl + entity.worker_image_link : null
                            return array
                        }))
                    }
                    if (response.data.next_page === true) {
                        this.page++
                        $state.loaded()
                    } else {
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
    },
}
</script>
