<template>
<b-row class="full-width">
    <b-col v-if="$q.screen.lt.sm" sm="12" md="2" class="bg-light p-3 p-md-4">
        <panel-picture :src="panel_picture"/>
    </b-col>
    <b-col sm="12" class="p-3 p-md-4 bg-white">
        <q-item class="full-width p-0 mb-2">
            <q-item-section>
                <div class="text-h6">{{ translate('personal_equipment') }}</div>
            </q-item-section>
            <q-item-section side v-if="!type_customer">
                <q-btn v-if="!$q.screen.lt.sm" no-caps color="primary" type="button" @click="showIncludeItemPanel">
                    <i class="fas fa-plus mr-1"></i> {{ translate('personal_equipments') }}
                </q-btn>
                <q-btn v-if="$q.screen.lt.sm" flat round class="full-width" no-caps color="primary" type="button" @click="showIncludeItemPanel">
                    <i class="fas fa-plus"></i>
                </q-btn>
            </q-item-section>
        </q-item>
        <q-separator />
        <div v-if="show_search === true" :class="this.$q.screen.lt.sm ? '' : 'bg-light p-4'">
            <search-wrapper-card :fullwidth_textinput="true" class="timeline-search shadow-2" :no_filters="true" form_type="equipments">
                <q-input slot="search_input" bottom-slots @input="isTypingPersonalEquipment = true" v-model="searchPersonalEquipment" id="filternamepersonalequipment" class="search-input" type="text" :placeholder="translate('find_personal_equipment')" :dense="true">
                    <template v-slot:prepend>
                        <q-icon name="search" />
                    </template>
                </q-input>
            </search-wrapper-card>
        </div>
        <div class="mt-4 text-bold">
            <i class="text-accent fas fa-list mr-2"></i>{{ translate('available_equipments') }}
        </div>
        <template>
            <q-list class="list_wrapper-personal_equipments">
                <q-item clickable v-for="(equipment,i) in equipments" :key="'equipment-' + i + '-' + equipment.id" class="list-item full-width">
                    <q-item-section>
                        <strong>
                            {{ equipment.equipment_name }}
                        </strong>
                    </q-item-section>
                </q-item>
            </q-list>
            <infinite-loading :identifier="infPersonalEquipments" slot="append" @infinite="getPersonalEquipments" />
        </template>
        <template v-if="!equipments || equipments.length === 0">
            <q-item class="list-group-item-accent-dark bg-light text-dark mt-2">
                <q-item-section>
                    <div>
                        {{ translate(no_results_message) }}<br />
                        <q-btn v-if="!type_customer" no-caps color="primary" type="button" @click="showIncludeItemPanel">
                            <i class="fas fa-plus mr-1"></i> {{ translate('personal_equipments') }}
                        </q-btn>
                    </div>
                </q-item-section>
            </q-item>
        </template>
    </b-col>
</b-row>
</template>

<script>
import {
    eventBus
} from "../../../main"
import AddIncludedItems from '../forms/AddIncludedItems'
import PanelPicture from '../../template-parts/PanelPicture'
import SearchWrapperCard from '../../template-parts/SearchWrapperCard'

export default {
    name: 'Equipment',
    props: ['customer_id'],
    components: {
        'panel-picture': PanelPicture,
        'search-wrapper-card': SearchWrapperCard,
    },
    data: function () {
        return {
            message_customer: 'no_available_equipments_yet',
            message_worker: 'no_available_equipments_yet_use_the_button_below_to_start_adding',
            type_customer: this.$appSettings.currentUserType() === 'customer' ? true : false,
            show_search: true,
            searchPersonalEquipment: '',
            isTypingPersonalEquipment: false,
            equipments: [],
            page: 1,
            state: {},
            infPersonalEquipments: +new Date()
        }
    },
    mounted: function () {
        this.$on('transition', this.resetMainList())
        eventBus.$on('update_included_items', () => {
            this.resetMainList()
        })
        eventBus.$on('updateCustomer', (item) => {
            this.resetMainList()
        })
    },
    watch: {
        searchPersonalEquipment: _.debounce(function () {
            this.isTypingPersonalEquipment = false
        }, 200),
        isTypingPersonalEquipment: function (value) {
            if (!value) {
                this.resetMainList()

                if (!this.medical_problems || this.medical_problems.length === 0) {
                    this.no_results_message = 'no_equipment_found_on_search'
                }
            }
        }
    },
    created: function () {
    },
    computed: {
        no_results_message: {
            // getter
            get: function () {
                return this.type_customer === true ? this.message_customer : this.message_worker
            },
            // setter
            set: function (newValue) {
                if (this.type_customer === true) {
                     this.message_customer = newValue
                } else {
                    this.message_worker = newValue
                }
            }
        },
        panel_picture: function () {
            return '/img/personal-files/echipament.jpg'
        },
        post_route: function () {
            return baseUrl + 'personal/equipments'
        },
        get_included_route: function () {
            return baseUrl + 'personal/equipments/customer'
        },
        get_non_included_route: function () {
            return baseUrl + 'equipments/personal/non_included'
        },
        delete_included_route: function () {
            return baseUrl + 'personal/equipments'
        },
        entity_type: function () {
            return 'personal_equipment'
        },
        parent_entity_type: function () {
            return 'equipment'
        },
        parent_post_route: function () {
            return baseUrl + 'equipments'
        },
        main_title: function () {
            return this.translate('personal_equipment')
        },
        placeholder_find_item: function () {
            return this.translate('find_equipment')
        },
    },
    methods: {
        resetMainList: function () {
            this.page = 1
            this.equipments = []
            this.infPersonalEquipments++
            eventBus.$emit('hideTabs')
        },
        getPersonalEquipments: function ($state) {
            var url = this.get_included_route + '/' + this.customer_id + '?page=' + this.page + '&items_per_page=' + this.$items_per_page + 
            (this.searchPersonalEquipment ? '&search=' + this.searchPersonalEquipment : '')
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            this.state = $state
            // To solve latency with server, push the items into a temporary scoped array and then into the main array.
            let equipments = this.equipments
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (response.data.items && response.data.items.length > 0) {
                        equipments.push(...response.data.items)
                    } 
                    if (response.data.next_page === true) {
                        this.page++
                        $state.loaded()
                    } else {
                        // To solve latency with server, push the items into a temporary scoped array and then into the main array.
                        this.equipments = equipments
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        showIncludeItemPanel: function () {
            const panelInstance = this.$showPanel({
                component: AddIncludedItems,
                props: {
                    field_unit: null,
                    add_button: true,
                    post_route: this.post_route,
                    get_included_route: this.get_included_route, 
                    get_non_included_route: this.get_non_included_route, 
                    delete_included_route: this.delete_included_route, 
                    item_id: this.customer_id, 
                    entity_type: this.entity_type, 
                    main_title: this.main_title, 
                    placeholder_find_item: this.placeholder_find_item,
                    parent_entity_type: this.parent_entity_type,
                    parent_post_route: this.parent_post_route, 
                }
            })

            panelInstance.promise
                .then(result => {
                    return this.resetMainList()
                })
        },
    },
}
</script>

<style lang="scss" scoped>
.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: .5rem 0;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }
}
</style>
