<template>
<div class="fit sticky-form-actions">
    <q-btn class="close-panel right top mt-2 position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless :style="'overflow-y: auto; height: 100vh; padding-bottom: ' + formActionsRowHeight + 'px; '">
        <b-tab class="p-0">
            <template slot="title">
                <q-item dense class="full-width p-0 min-height-0">
                    <q-item-section avatar>
                        <q-avatar class="shadow-2">
                            <img v-if="customer_image_link" :src="customer_image_link">
                            <i v-else class="fas fa-user fa-1x text-muted"></i>
                        </q-avatar>
                    </q-item-section>
                    <q-item-section>
                        <span class="text-muted" v-if="details === true">{{ translate('details_training') }}</span>
                        <span class="text-muted" v-if="details !== true">{{ translate('preview_training') }}</span>
                        <h4 class="mb-0 align-self-start">{{ customer_name }}</h4>
                    </q-item-section>
                </q-item>
            </template>
            <b-list-group class="list-group-accent full-width">
                <div class="full-height">
                    <b-list-group-item class="bg-white">
                        <div class="mb-3">
                            <strong class="text-blue-10">{{ item.name }}</strong><br />
                            <i class="font-weight-bold text-indigo-8 icon-calendar mr-1"></i> {{ prettyDate(date) }}
                        </div>
                        <div class="m-0 p-0 row align-items-start">
                            <div class="col-xs-12 col-md-5 p-0">
                                <q-item clickable v-for="(collection,i) in item.exercises_collections" :key="'exercises-collection-' + i + '-' + collection.id" class="full-width p-0 shadow-1 mt-2 mb-2">
                                    <q-item-section>
                                        <q-item-label class="bg-blue-2 pl-3 pr-3 pt-2 pb-2">
                                            <!-- <strong class="text-blue-6 mr-2" dense>{{ i+1 }}</strong> -->
                                            <template v-if="collection.rounds">
                                                <!-- rounds -->
                                                <strong class="text-blue">{{ collection.rounds }}</strong> <span class="mr-1">x</span>
                                            </template>
                                            <template v-if="collection.seconds">
                                                <!-- seconds -->
                                                <strong class="text-primary mr-1">{{ secondsToFormattedTime(collection.seconds) }}</strong>
                                            </template>
                                            <!-- exercise_collection_name -->
                                            <strong class="text-blue-10">{{ collection.name }}</strong>
                                        </q-item-label>
                                        <q-list dense v-if="collection.exercises" class="bg-white">
                                            <div v-for="(exercise,j) in collection.exercises" :key="'exercise-' + j + '-' + exercise.id">
                                                <q-item dense clickable class="align-items-center min-height-0">
                                                    <q-item-section>
                                                        <q-item-label line="1">
                                                            <span>
                                                                <!-- exercise_name -->
                                                                <strong class="text-blue-10 mr-1">{{ exercise.name }}</strong>
                                                                <!-- reps -->
                                                                <span class="mr-1">x</span><strong class="mr-1">{{ exercise.reps }}</strong>
                                                                <span class="mr-1">@</span>
                                                                <!-- value unit -->
                                                                <strong class="mr-1">{{ exercise.value_per_rep }}{{ exercise.unit_name }}</strong>
                                                            </span>
                                                        </q-item-label>
                                                        <q-item-label line="2" v-if="exercise.unavailable_equipments && exercise.unavailable_equipments.length">
                                                            <div class="pl-3">
                                                                <!-- <i class="text-danger font-weight-bold fas fa-dumbbell mr-1"></i> -->
                                                                <q-icon size="0.75rem" flat dense name="fas fa-dumbbell" color="danger" class="mr-1">
                                                                    <q-tooltip :offset="[10, 10]">{{ translate('unavailable_equipment') }}</q-tooltip>
                                                                </q-icon>
                                                                {{ exercise.unavailable_equipments.map(entity => {return entity.equipment_name}).join(', ') }}
                                                            </div>
                                                        </q-item-label>
                                                        <q-item-label line="3" dense v-if="exercise.personal_medical_problems && exercise.personal_medical_problems.length">
                                                            <div class="pl-3">
                                                                <!-- <i class="text-danger font-weight-bold fas fa-dumbbell mr-1"></i> -->
                                                                <q-icon size="0.75rem" flat dense name="fas fa-heart-broken" color="danger" class="mr-1">
                                                                    <q-tooltip :offset="[10, 10]">{{ translate('personal_medical_problems') }}</q-tooltip>
                                                                </q-icon>
                                                                {{ exercise.personal_medical_problems.map(entity => {return entity.medical_problem_name + ' (' + entity.reference + ')'}).join(', ') }}
                                                            </div>
                                                        </q-item-label>
                                                    </q-item-section>
                                                </q-item>
                                            </div>
                                        </q-list>
                                        <q-item v-if="collection.exercises.length === 0">
                                            <q-item-section side>No exercises found.</q-item-section>
                                        </q-item>
                                    </q-item-section>
                                </q-item>
                                <q-item v-if="item.exercises_collections.length === 0">
                                    <q-item-section side>No exercises collections found.</q-item-section>
                                </q-item>
                                <template v-if="item.muscles && item.muscles.length">
                                    <q-card-section class="p-0 mt-3">
                                        <div class="text-subtitle3">
                                            <q-icon size="0.75rem" flat dense name="fas fa-bone" color="indigo-10" class="mr-1">
                                                <q-tooltip :offset="[10, 10]">{{ translate('muscles') }}</q-tooltip>
                                            </q-icon>
                                            {{ translate('muscles') }}
                                            </div>
                                        <q-separator />
                                        <span v-for="(muscles,i) in item.muscles" :key="'personal-training-muscle-' + i">
                                            <span v-for="(m,j) in muscles" :key="'muscle-' + i + '-' + j">
                                                {{ m }}<template v-if="i+1 < item.muscles.length">, </template>
                                            </span>
                                        </span>
                                    </q-card-section>
                                </template>
                                <template v-if="item.equipments && item.equipments.length">
                                    <q-card-section class="p-0 mt-3">
                                        <div class="text-subtitle3">
                                            <q-icon size="0.75rem" flat dense name="fas fa-dumbbell" color="indigo-10" class="mr-1">
                                                <q-tooltip :offset="[10, 10]">{{ translate('equipments') }}</q-tooltip>
                                            </q-icon>
                                            {{ translate('equipments') }}
                                        </div>
                                        <q-separator />
                                        <span v-for="(equipments,i) in item.equipments" :key="'personal-training-muscle-' + i">
                                            <span v-for="(e,j) in equipments" :key="'muscle-' + i + '-' + j">
                                                {{ e }}<template v-if="i+1 < item.equipments.length">, </template>
                                            </span>
                                        </span>
                                    </q-card-section>
                                </template>
                            </div>
                            <div class="col-xs-12 col-md p-2 p-md-4 pt-0">
                                <body-svg :paths="item.muscles" :schedule_id="item.id" />
                            </div>
                        </div>
                    </b-list-group-item>
                </div>
                <div class="form-actions bottom bg-light" :style="'width: ' + slideoutTabsWidth + 'px'">
                    <q-item class="p-3">
                        <q-item-section side>
                            <q-btn v-if="details !== true" type="submit" no-caps color="info" @click.prevent="register">
                                <q-icon size="1rem" name="check" class="mr-1" /> {{ translate('save') }}
                            </q-btn>
                        </q-item-section>
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('cancel') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </div>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import BodySVG from '../template-parts/BodySVG'

export default {
    name: 'AssignedTrainingDetails',
    props: ['training_id', 'customer_id', 'customer_name', 'customer_image_link', 'date', 'details'],
    components: {
        'body-svg': BodySVG
    },
    data: function () {
        return {
            formActionsRowHeight: 0,
            slideoutTabsWidth: 0,
            item: {
                exercises_collections: []
            }
        }
    },
    created: function () {
        this.getItem()
        this.getElementsWidthHeight()
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.getElementsWidthHeight)
    },
    mounted: function () {
        window.addEventListener('resize', this.getElementsWidthHeight)
    },
    methods: {
        getElementsWidthHeight: _.debounce(function () {
            this.formActionsRowHeight = document.querySelector('.form-actions').clientHeight
            this.slideoutTabsWidth = document.querySelector('.slideout .tabs').clientWidth
        }, 200),
        register: function () {
            var data = {
                'date': this.date,
                'training_id': this.training_id,
                'customer_id': this.customer_id
            }

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            };

            axios.post(baseUrl + 'personal/trainings', data, {
                    headers: headers
                })
                .then(response => {
                    this.msg = response.data.msg;
                    if (response.data.status) {
                        var message = 'assign_training_' + this.msg
                        var translated_message = this.translate(message)
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        this.$emit('closePanel', {
                            status: true
                        })
                    } else {
                        var key = response.data.key ? '_' + response.data.key : ''
                        var message = 'assign_training_' + this.msg + key
                        var translated_message = this.translate(message)
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }

                })
                .catch(error => {
                    var msg = error.response.data.msg;
                    if (msg != undefined) {
                        this.msg = msg;
                        this.$toasted.error('An error has occured', {
                            duration: this.$toasted_duration
                        })
                    }

                })
        },
        secondsToFormattedTime: function (seconds) {
            const formatted_time = new Date(seconds * 1000).toISOString().substr(11, 8)
            return formatted_time
        },
        getItem: function () {
            var headers = {
                "Authorization": "Bearer " + this.accessToken()
            }
            var url = baseUrl + 'personal/trainings/preview?training_id=' + this.training_id + '&customer_id=' + this.customer_id
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    this.item = response.data.item
                })  
                .catch(function (error) {
                    // console.log(error)
                })
        },
        closePanel: function () {
            this.$emit('closePanel', {})
        }
    }
}
</script>
