<template>
<b-row class="full-width">
    <b-col v-if="$q.screen.lt.sm" sm="12" md="2" class="bg-light p-3 p-md-4">
        <panel-picture :src="panel_picture" />
    </b-col>
    <b-col sm="12" class="p-3 p-md-4 bg-white">
        <q-item class="full-width p-0 mb-2">
            <q-item-section>
                <div class="text-h6">{{ translate('notes') }}</div>
            </q-item-section>
            <q-item-section side>
                <q-btn v-if="!$q.screen.lt.sm" no-caps color="primary" type="button" @click="showAddPersonalNotesPanel">
                    <i class="fas fa-plus mr-1"></i> {{ translate('notes') }}
                </q-btn>
                <q-btn v-if="$q.screen.lt.sm" flat round class="full-width" no-caps color="primary" type="button" @click="showAddPersonalNotesPanel">
                    <i class="fas fa-plus"></i>
                </q-btn>
            </q-item-section>
        </q-item>
        <q-separator />
        <div class="mt-4 text-bold mb-4">
            <i class="text-blue fas fa-history mr-2"></i>{{ translate('added_notes') }}
        </div>
        <div class="d-flex wrap list_wrapper-personal_notes personal_notes">
            <div class="col-md-4 col-lg-4 col-xl-3" v-for="(note,i) in notes" :key="'note-' + i + '-' + note.id">
                <q-card class="full-width m-0 bg-yellow-2">
                    <q-item class="note-header p-2">
                        <q-item-section class="p-0" side>{{ prettyDate(note.created_at) }}</q-item-section>
                        <q-space />
                        <q-item-section class="p-0" side><q-btn @click.prevent="showDeleteItemPanel(note.id)" flat round color="danger" icon="delete" size="sm" dense no-caps /></q-item-section>
                    </q-item>
                    <q-separator />
                    <q-item class="bg-yellow-2 p-2" clickable @click="showNoteDetailsPanel(note.id, prettyDate(note.created_at))">{{ note.notes }}</q-item>
                </q-card>
            </div>
        </div>
        <infinite-loading :identifier="infPersonalNotes" slot="append" @infinite="getPersonalNotes" />
        <template v-if="!notes || notes.length === 0">
            <q-item class="list-group-item-accent-dark bg-light text-dark mt-2">
                <q-item-section>
                    <div>
                        {{ translate(no_results_message) }}<br />
                        <q-btn no-caps color="primary" type="button" @click="showAddPersonalNotesPanel">
                            <i class="fas fa-plus mr-1"></i> {{ translate('notes') }}
                        </q-btn>
                    </div>
                </q-item-section>
            </q-item>
        </template>
    </b-col>
</b-row>
</template>

<script>
import {
    eventBus
} from "../../../main"
import AddPersonalNotes from '../forms/AddPersonalNotes'
import NoteDetails from '../show/NoteDetails'
import DeleteItem from '../forms/DeleteItem'
import PanelPicture from '../../template-parts/PanelPicture'

export default {
    name: 'PersonalNotes',
    props: ['customer_id', 'customer_name'],
    data: function () {
        return {
            no_results_message: 'no_notes_yet_use_the_button_below_to_start_adding',
            notes: [],
            page: 1,
            state: {},
            infPersonalNotes: +new Date(),
        }
    },
    components: {
        'panel-picture': PanelPicture,
    },
    computed:  {
        panel_picture: function () {
            return '/img/personal-files/notite.jpg'
        },
        post_route: function () {
            return baseUrl + 'personal/notes'
        },
        get_route: function () {
            return baseUrl + 'personal/notes'
        },
        delete_route: function () {
            return baseUrl + 'personal/notes'
        },
        entity_type: function () {
            return 'personal_note'
        },
        panel_title: function () {
            return this.translate('delete_personal_note')
        },
        panel_message: function () {
            return this.translate('deleting_personal_note')
        },
        placeholder_find_item: function () {
            return this.translate('find_note')
        },
    },
    created: function () {
        this.$on('transition', this.resetMainList())
        eventBus.$on('update_all', () => {
             this.resetMainList()
        })
        eventBus.$on('updateCustomer', (item) => {
            this.resetMainList()
        })
    },
    methods: {
        resetMainList: function () {
            this.page = 1
            this.notes = []
            this.infPersonalNotes++
            eventBus.$emit('hideTabs')
        },
        getPersonalNotes: function ($state) {
            var url = baseUrl + 'personal/notes/customer/' + this.customer_id + '?page=' + this.page + '&items_per_page=' + this.$items_per_page
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            this.state = $state
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (response.data.items && response.data.items.length > 0) {
                        this.notes.push(...response.data.items)
                    }  else {
                        this.notes = []
                    }
                    if (response.data.next_page === true) {
                        this.page++
                        $state.loaded()
                    } else {
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        showAddPersonalNotesPanel: function (measurement) {
            const panelInstance = this.$showPanel({
                component: AddPersonalNotes,
                props: {
                    measurement: measurement,
                    customer_id: this.customer_id,
                    post_route: this.post_route,
                    customer_name: this.customer_name,
                    entity_type: this.entity_type,
                    placeholder_find_item: this.placeholder_find_item,
                }
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.resetMainList()
                    }
                })
        },
        showDeleteItemPanel: function (id) {
            const panelInstance = this.$showPanel({
                component: DeleteItem,
                props: {
                    item_id: id,
                    entity_type: this.entity_type,
                    delete_route: this.delete_route,
                    panel_title: this.panel_title,
                    panel_message: this.panel_message
                }
            })
        },
        showNoteDetailsPanel: function (id, date) {
            const panelInstance = this.$showPanel({
                component: NoteDetails,
                props: {
                    note_id: id,
                    date: date,
                    customer_name: this.customer_name
                }
            })
        },
    },
}
</script>

<style lang="scss">
.personal_notes {
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 7.5px;
    padding-right: 7.5px;

    > div {
        padding: 0.25rem;
    }
    .q-item {
        min-height: 57px;
    }
    .note-header.q-item {
        min-height: 0;
    }
}
</style>