<template>
<div class="fit">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless>
        <b-tab class="p-0">
            <template slot="title">
                <q-icon name="archive" />
            </template>
            <b-list-group class="list-group-accent">
                <b-form @submit.prevent="register">
                    <b-list-group-item class="list-group-item-accent-secondary bg-light font-weight-bold text-uppercase">
                        <q-icon name="add" />&nbsp;
                        {{ translate('add_' + entity_type) }}
                    </b-list-group-item>
                    <b-list-group-item class="list-group-item-accent-primary bg-custom-white">
                        <q-item class="p-0">
                            <q-item-section side><q-icon color="primary" size="md" name="fas fa-user" /></q-item-section>
                            <q-item-section>
                                <small class="text-muted">{{ translate('customer') }}</small>
                                <strong>{{ customer_name }}</strong>
                            </q-item-section>
                        </q-item>
                    </b-list-group-item>
                    <b-list-group-item class="list-group-item-accent-info bg-custom-lightblue font-weight-bold">
                        {{ translate('notes') }}*
                    </b-list-group-item>
                    <b-list-group-item class="list-group-item-accent-info">
                        <textarea v-model="personal_note.notes" class="form-control" rows="3" :placeholder="translate('personal_notes')"></textarea>
                    </b-list-group-item>
                    <q-separator />
                    <b-list-group-item class="list-group-item-accent-info">
                        <q-item class="p-0 full-width">
                            <q-item-section side>
                                <q-btn type="submit" no-caps color="info">
                                    <q-icon size="1rem" name="check" class="mr-1" /> {{ translate('save') }}
                                </q-btn>
                            </q-item-section>
                            <q-item-section></q-item-section>
                            <q-item-section side>
                                <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                    <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('cancel') }}
                                </q-btn>
                            </q-item-section>
                        </q-item>
                    </b-list-group-item>
                </b-form>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import {
    eventBus
} from "../../../main"

export default {
    name: 'AddPersonalNotes',
    props: ['post_route', 'customer_id', 'entity_type', 'customer_name', 'placeholder_find_item', 'date'],
    data: function () {
        return {
            searchItem: '',
            isTypingItem: false,
            state: {},
            page: 1,
            items: [],
            infItems: +new Date(),
            personal_note: {
                notes: '',
                customer_id: this.customer_id
            }
        }
    },
    methods: {
        register: function () {
            var data = {
                'notes': this.personal_note.notes,
                'customer_id': this.personal_note.customer_id
            }

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            };

            axios.post(baseUrl + 'personal/notes', data, {
                    headers: headers
                })
                .then(response => {
                    this.msg = response.data.msg;
                    if (response.data.status) {
                        var message = 'add_notes_' + this.msg
                        var translated_message = this.translate(message)
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        this.$emit('closePanel', {
                            status: true
                        })
                    } else {
                        var key = response.data.key ? '_' + response.data.key : ''
                        var message = 'add_notes_' + this.msg + key
                        var translated_message = this.translate(message)
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }

                })
                .catch(error => {
                    var msg = error.response.data.msg;
                    if (msg != undefined) {
                        this.msg = msg;
                        this.$toasted.error('An error has occured', {
                            duration: this.$toasted_duration
                        })
                    }

                })
        },
        closePanel: function () {
            this.$emit('closePanel', {})
        }
    }
}
</script>

<style lang="scss" scoped>
.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: .5rem 0;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }
}
</style>
