<template>
<b-row class="full-width">
    <b-col v-if="$q.screen.lt.sm" sm="12" md="2" class="bg-light p-3 p-md-4">
        <panel-picture :src="panel_picture" />
    </b-col>
    <b-col sm="12" class="p-3 p-md-4 bg-white">
        <q-tabs :class="$q.screen.lt.sm ? 'height-auto' : ''" :vertical="$q.screen.lt.sm ? true : false" v-if="$appSettings.currentUserType() !== 'customer'" dense clickable no-caps v-model="subtab" indicator-color="primary" class="no-controls personal-file-tabs mb-2" align="left">
            <q-tab name="organizedtrainings" class="bg-light justify-content-start">
                <div>
                    <i class="fas fa-chart-pie mr-2 fa-lg"></i> <span>{{ translate('organized_trainings') }}</span>
                </div>
            </q-tab>
            <q-tab name="absences" class="bg-light justify-content-start">
                <div>
                    <i class="fas fa-clock mr-2 fa-lg"></i> <span>{{ translate('absences') }}</span>
                </div>
            </q-tab>
            <q-tab name="cancellations" class="bg-light justify-content-start">
                <div>
                    <i class="fas fa-calendar-times mr-2 fa-lg"></i> <span>{{ translate('cancellations') }}</span>
                </div>
            </q-tab>
        </q-tabs>
        <template v-if="subtab === 'organizedtrainings'">
            <organized-trainings :customer_id="customer_id" />
        </template>
        <template v-if="subtab === 'absences'">
            <absences :customer_id="customer_id" />
        </template>
        <template v-if="subtab === 'cancellations'">
            <cancellations :customer_id="customer_id" />
        </template>
    </b-col>
</b-row>
</template>

<script>
import PanelPicture from '../../template-parts/PanelPicture'
import OrganizedTrainings from './OrganizedTrainings'
import Absences from './Absences'
import Cancellations from './Cancellations'

export default {
    name: 'Trainings',
    props: ['customer_id'],
    components: {
        'organized-trainings': OrganizedTrainings,
        'absences': Absences,
        'cancellations': Cancellations,
        'panel-picture': PanelPicture
    },
    computed: {
        panel_picture: function () {
            return '/img/personal-files/antrenament_02.jpg'
        },
    },
    data: function () {
        return {
            subtab: 'organizedtrainings',
        }
    },
}
</script>

<style lang="scss" scoped>
.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: .5rem 0;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }
}
.trainings-timeline {
    margin-bottom: 2px!important;
}
.logged-list {
    .list-item {
        min-height: 0;
    }
}
</style>
