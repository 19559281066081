<template>
<div class="fit">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless>
        <b-tab class="p-0">
            <template slot="title">
                <q-icon name="archive" />
            </template>
            <b-list-group class="list-group-accent">
                <b-list-group-item class="list-group-item-accent-primary bg-custom-white">
                    <q-item class="p-0">
                        <q-item-section side><q-icon color="primary" size="md" name="fas fa-user" /></q-item-section>
                        <q-item-section>
                            <small class="text-muted">{{ translate('customer') }}</small>
                            <strong>{{ customer_name }}</strong>
                        </q-item-section>
                    </q-item>
                </b-list-group-item>
                <b-list-group-item class="list-group-item-accent-info bg-white">
                    <q-card class="bg-yellow-2 p-2">
                        <q-item class="p-0 pb-2 height-auto">
                            <q-item-section>
                                <span class="text-muted float-right">{{ date }}</span>
                            </q-item-section>
                        </q-item>
                        <q-separator />
                        <q-item class="p-0 pt-2 pb-2 height-auto">
                            <!-- Keep next line always on the same align to apply correctly the pre-formatted class. -->
                            <q-item-section class="pre-formatted">{{ item.notes }}</q-item-section>
                        </q-item>
                    </q-card>
                </b-list-group-item>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
export default {
    name: 'NoteDetails',
    props: ['note_id', 'customer_name', 'date'],
    data: function () {
        return {
            item: {}
        }
    },
    created: function () {
        this.getItem()
    },
    methods: {
        getItem: function () {
            var headers = {
                "Authorization": "Bearer " + this.accessToken(),
                "Content-Type": "application/json"
            }
            var url = baseUrl + 'personal/notes/' + this.note_id
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    this.item = response.data.item
                })  
                .catch(function (error) {
                    // console.log(error)
                })
        },
        closePanel: function () {
            this.$emit('closePanel', {})
        }
    }
}
</script>

<style lang="scss" scoped>
.q-item.height-auto {
    min-height: 0;
}
.pre-formatted {
    white-space: pre-line;
}
</style>
