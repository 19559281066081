<template>
<div class="fit">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless>
        <b-tab class="p-0">
            <template slot="title">
                <q-icon name="archive" />
            </template>
            <b-list-group class="list-group-accent">
                <b-form @submit.prevent="register">
                    <b-list-group-item class="list-group-item-accent-secondary bg-light font-weight-bold text-uppercase">
                        <q-icon name="add" />&nbsp;
                        {{ translate('add_' + entity_type) }}
                    </b-list-group-item>
                    <b-list-group-item class="list-group-item-accent-primary bg-custom-white">
                        <q-item class="p-0">
                            <q-item-section side><q-icon color="primary" size="md" name="fas fa-user" /></q-item-section>
                            <q-item-section>
                                <small class="text-muted">{{ translate('customer') }}</small>
                                <strong>{{ customer_name }}</strong>
                            </q-item-section>
                        </q-item>
                    </b-list-group-item>
                    <b-list-group-item class="list-group-item-accent-info bg-custom-lightblue font-weight-bold">
                        {{ translate('choose_medical_problem') }}*
                    </b-list-group-item>
                    <b-list-group-item class="list-group-item-accent-info">
                        <div v-if="!items || items.length == 0">
                            <div class="text-muted">{{ translate('no_results') }}</div>
                        </div>
                        <div v-else>
                            <div class="text-muted">{{ translate('results_found') }}: {{ items.length }}</div>
                        </div>
                        <q-input class="search-input mb-0" @input="isTypingItem = true" v-model="searchItem" id="filternameitem" type="text" :placeholder="placeholder_find_item ? translate(placeholder_find_item) : ''" :dense="true">
                            <template v-slot:prepend>
                                <q-icon name="search" />
                            </template>
                            <template v-slot:append>
                                <q-btn :size="$q.screen.lt.sm ? '0.65rem' : null" type="button" @click.stop="showAddMedicalProblemPanel" flat dense color="dark" no-caps :label="add_button_text" icon="add" />
                            </template>
                        </q-input>
                        <div class="items-container" :class="'list_wraper-' + entity_type + '-' + customer_id">
                            <q-item tag="label" class="list-item full-width" clickable v-ripple v-for="(item,i) in items" :key="'list-item-' + i + '-' + item.id">
                                <q-item-section side>
                                    <q-radio v-model="personal_medical_problem.medical_problem_id" icon="add" dense flat color="primary" :val="item.id" />
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label>{{ item.name }}</q-item-label>
                                </q-item-section>
                            </q-item>
                        </div>
                        <infinite-loading @infinite="getItems" ref="infiniteLoading" :identifier="'included-items-' + entity_type + '-' + customer_id + '-' +infItems" slot="append" />
                    </b-list-group-item>
                    <b-list-group-item class="list-group-item-accent-info bg-custom-lightblue font-weight-bold">
                        {{ translate('description') }}
                    </b-list-group-item>
                    <b-list-group-item class="list-group-item-accent-info">
                        <textarea v-model="personal_medical_problem.description" class="form-control" rows="3" :placeholder="translate('personal_medical_problem_description')"></textarea>
                    </b-list-group-item>
                    <q-separator />
                    <b-list-group-item class="list-group-item-accent-info">
                        <q-item class="p-0 full-width">
                            <q-item-section side>
                                <q-btn type="submit" no-caps color="info">
                                    <q-icon size="1rem" name="check" class="mr-1" /> {{ translate('save') }}
                                </q-btn>
                            </q-item-section>
                            <q-item-section></q-item-section>
                            <q-item-section side>
                                <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                    <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('cancel') }}
                                </q-btn>
                            </q-item-section>
                        </q-item>
                    </b-list-group-item>
                </b-form>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import {
    eventBus
} from "../../../main"

import IncludeItemForm from './IncludeItem'
import AddMedicalProblem from '../../data-libraries/template-parts/forms/AddItem'

export default {
    name: 'AddPersonalMedicalProblem',
    props: ['post_route', 'get_route', 'customer_id', 'entity_type', 'customer_name', 'placeholder_find_item', 'date'],
    components: {
        'include-item-form': IncludeItemForm
    },
    watch: {
        searchItem: _.debounce(function () {
            this.isTypingItem = false
        }, 200),
        isTypingItem: function (value) {
            if (!value) {
                this.resetMainList()
            }
        }
    },
    computed: {
        container_class: function () {
            return 'medical_problems'
        },
        parent_entity_type: function () {
            return 'medical_problem'
        },
        parent_post_route: function () {
            return baseUrl + 'medical_problems'
        },
        main_title: function () {
            return this.translate('medical_problems')
        },
        add_button_text: function () {
            return this.translate('new_medical_problem')
        },
        add_item_title: function () {
            return this.translate('add_medical_problem')
        },
    },
    data: function () {
        return {
            searchItem: '',
            isTypingItem: false,
            state: {},
            page: 1,
            items: [],
            infItems: +new Date(),
            personal_medical_problem: {
                description: '',
                medical_problem_id: null,
                customer_id: this.customer_id
            }
        }
    },
    methods: {
        showAddMedicalProblemPanel: function () {
            const panelInstance = this.$showPanel({
                component: AddMedicalProblem,
                props: {
                    add_item_title: this.add_item_title,
                    post_route: this.parent_post_route,
                    entity_type: this.parent_entity_type,
                    field_unit: this.field_unit,
                    field_description: this.field_description,
                }
            })

            panelInstance.promise.then(result => {
                if (result && result.status) {
                    this.resetMainList()
                }
            })
        },
        resetMainList: function () {
            this.page = 1
            this.items = []
            this.infItems++
        },
        getItems: function ($state) {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }
            var url = this.get_route + '?page=' + this.page + '&items_per_page=' + this.$items_per_page + 
            (this.searchItem ? '&search=' + this.searchItem : '')
            this.state = $state
            // To solve latency with server, push the items into a temporary scoped array and then into the main array.
            let items = this.items
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (response.data.items && response.data.items.length > 0) {
                        items.push(...response.data.items)
                    } 
                    if (response.data.next_page === true) {
                        this.page++
                        $state.loaded()
                    } else {
                        // To solve latency with server, push the items into a temporary scoped array and then into the main array.
                        this.items = items
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        register: function () {
            var data = {
                'description': this.personal_medical_problem.description,
                'medical_problem_id': this.personal_medical_problem.medical_problem_id,
                'customer_id': this.personal_medical_problem.customer_id
            }

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            };

            axios.post(baseUrl + 'personal/medical_problems', data, {
                    headers: headers
                })
                .then(response => {
                    this.msg = response.data.msg;
                    if (response.data.status) {
                        var message = 'add_medical_problems_' + this.msg
                        var translated_message = this.translate(message)
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        this.$emit('closePanel', {
                            status: true
                        })
                    } else {
                        var key = response.data.key ? '_' + response.data.key : ''
                        var message = 'add_medical_problems_' + this.msg + key
                        var translated_message = this.translate(message)
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }

                })
                .catch(error => {
                    var msg = error.response.data.msg;
                    if (msg != undefined) {
                        this.msg = msg;
                        this.$toasted.error('An error has occured', {
                            duration: this.$toasted_duration
                        })
                    }

                })
        },
        closePanel: function () {
            this.$emit('closePanel', {})
        }
    }
}
</script>

<style lang="scss" scoped>
.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: .5rem 0;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }
}
</style>
