<template>
<div>
    <div class="mt-4 mb-2 text-bold">
        <i class="text-indigo fas fa-history mr-2"></i>{{ translate('cancellations') }}
    </div>
    <template>
        <q-timeline color="light-blue-9" class="list_wrapper-cancellations trainings-timeline">
            <q-timeline-entry v-for="(cancellation,i) in cancellations" :key="'absence-' + i + '-' + cancellation.id">
                <template slot="title">
                    <q-item @click="toggleInfo(cancellation.id, i)" clickable class="m-0 p-0 cursor-pointer col-xs-12 col-md-5" style="min-height: 0;" :class="$q.screen.xs ? 'small' : ''">
                        <q-item-section side class="text-light-blue-9 bg-lightrey-2 col-6 col-md-8 p-0 arrow-right">
                            <div class="p-1 pl-2 pr-2 font-weight-normal">
                                <i :class="cancellation.show ? 'fas fa-angle-up' : 'fas fa-angle-down'" class="mr-1" size="sm"></i>
                                {{ prettyDate(cancellation.date) }}
                            </div>
                        </q-item-section>
                        <q-item-section class="text-center bg-lightrey-2 p-0">
                            <div class="text-black p-1 pl-2 pr-2">{{ cancellation.service ? cancellation.service : '' }}</div>
                        </q-item-section>
                    </q-item>
                </template>
                <template v-if="cancellation.info && cancellation.show">
                    <q-card flat class="cursor-pointer col-xs-12 col-md-5 m-0 p-0" :class="$q.screen.xs ? 'small' : ''">
                        <q-card-section class="p-0 pb-1">
                            <div class="row align-items-center">
                                <div class="col-12 col-sm-6 col-md-4">
                                    <small class="text-muted">{{ translate('canceled_at') }}</small><br />
                                    {{ prettyDate(cancellation.info.canceled_at) }}
                                </div>
                                <div v-if="cancellation.info.canceled_by" class="col-12 col-sm-6 col-md-4">
                                    <small class="text-muted">{{ translate('canceled_by') }}</small><br />
                                    <q-chip color="light" class="pl-0">
                                        <q-avatar v-if="cancellation.info.canceled_by_image_link">
                                            <img :src="cancellation.info.canceled_by_image_link">
                                        </q-avatar>
                                        <i v-else class="fas fa-user text-muted"></i>
                                        {{ cancellation.info.canceled_by }}
                                    </q-chip>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4">
                                    <small class="text-muted">{{ translate('schedule_created_at') }}</small><br />
                                    {{ prettyDate(cancellation.info.schedule_created_at) }}
                                </div>
                                <div class="col-12 col-sm-6 col-md-4">
                                    <small class="text-muted">{{ translate('schedule_created_by') }}</small><br />
                                    <q-chip color="light" class="pl-0">
                                        <q-avatar v-if="cancellation.info.schedule_created_by_image_link">
                                            <img :src="cancellation.info.schedule_created_by_image_link">
                                        </q-avatar>
                                        <i v-else class="fas fa-user text-muted"></i>
                                        {{ cancellation.info.schedule_created_by }}
                                    </q-chip>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4">
                                    <small class="text-muted">{{ translate('schedule_date') }}</small><br />
                                    {{ prettyDate(cancellation.info.schedule_date) }}
                                </div>
                            </div>
                        </q-card-section>
                    </q-card>
                </template>
            </q-timeline-entry>
        </q-timeline>
        <template v-if="!cancellations || cancellations.length === 0">
            {{ translate('no_results') }}
        </template>
        <infinite-loading :identifier="infCancellations" slot="append" @infinite="getItems" />
    </template>
</div>
</template>

<script>
import {
    eventBus
} from "../../../main"

export default {
    name: 'Cancellations',
    props: ['customer_id'],
    data: function () {
        return {
            type_customer: this.$appSettings.currentUserType() === 'customer' ? true : false,
            cancellations: [],
            page: 1,
            state: {},
            infCancellations: +new Date()
        }
    },
    mounted: function () {
        this.$on('transition', this.getItems)
        eventBus.$emit('hideTabs')
        eventBus.$on('update_included_items', () => {
            this.resetMainList()
        })
        eventBus.$on('updateCustomer', (item) => {
            this.resetMainList()
        })
    },
    created: function () {},
    computed: {
        get_route: function () {
            return baseUrl + 'canceled_schedules/customer'
        },
        entity_type: function () {
            return 'absence'
        },
    },
    methods: {
        toggleInfo: function (canceled_schedule_id, index) {
            this.cancellations[index].show = !this.cancellations[index].show
            if (this.cancellations[index].show) {
                this.getCancellationInfo(canceled_schedule_id, index)
            }
        },
        getCancellationInfo: function (canceled_schedule_id, index) {
            var url = baseUrl + 'canceled_schedules/' + canceled_schedule_id
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    if (response.data.item && response.data.status) {
                        let data_item = response.data.item
                        this.cancellations[index].info = data_item
                        this.cancellations[index].info.canceled_by_image_link = data_item.canceled_by_image_link ? baseUrl + data_item.canceled_by_image_link : null
                        this.cancellations[index].info.schedule_created_by_image_link = data_item.schedule_created_by_image_link ? baseUrl + data_item.schedule_created_by_image_link : null
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        secondsToFormattedTime: function (seconds) {
            const formatted_time = new Date(seconds * 1000).toISOString().substr(11, 8)
            return formatted_time
        },
        resetMainList: function () {
            this.page = 1
            this.cancellations = []
            this.infCancellations++
        },
        getItems: function ($state) {
            var url = this.get_route + '?customer_id=' + this.customer_id + '&page=' + this.page + '&items_per_page=' + this.$items_per_page
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            this.state = $state
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (response.data.items && response.data.items.length > 0) {
                        this.cancellations.push(...response.data.items.map(entity => {
                            let array = entity
                            array['show'] = false
                            array['info'] = null
                            return array
                        }))
                    }
                    if (response.data.next_page === true) {
                        this.page++
                        $state.loaded()
                    } else {
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
    },
}
</script>
