<template>
<b-row class="full-width">
    <b-col sm="12" md="2" class="bg-light p-3 p-md-4">
        <profile-picture :src="customer.image_link"/>
        <q-item tag="label" clickable class="mt-2 p-2 bg-positive text-white" v-ripple style="min-height: 0">
            <q-item-section class="p-0" side>
                <q-icon class="mr-1" size="1.5rem" color="light" name="fas fa-photo"></q-icon>
            </q-item-section>
            <q-item-section class="p-0 text-center">
                <small>{{ translate('add_image') }}</small>
            </q-item-section>
            <q-item-section class="p-0 text-right" side>
                <q-icon size="1rem" color="light" name="fas fa-upload"></q-icon>
                <b-form-file class="hidden" type="file" name="selectedFile" @change="onFileSelected" v-model="selectedFile"></b-form-file>
            </q-item-section>
        </q-item>
        <q-item tag="label" clickable class="p-2 bg-negative text-white" v-ripple style="min-height: 0" @click.stop="showDeleteProfileImagePanel()">
            <q-item-section class="p-0" side>
                <q-icon class="mr-1" size="1.5rem" color="light" name="fas fa-photo"></q-icon>
            </q-item-section>
            <q-item-section class="p-0 text-center">
                <small>{{ translate('delete_image') }}</small>
            </q-item-section>
            <q-item-section class="p-0 text-right" side>
                <q-icon size="1rem" color="light" name="delete"></q-icon>
            </q-item-section>
        </q-item>
    </b-col>
    <b-col sm="12" md="10" class="p-3 p-md-4 bg-white">
        <q-item v-if="!$q.screen.lt.sm" class="full-width p-0 mb-2">
            <q-item-section>
                <div class="text-h6">{{ translate('personal_data') }}</div>
            </q-item-section>
            <q-item-section side>
                <q-btn no-caps color="primary" type="button" @click="showEditPanel">
                    <i class="fas fa-edit mr-1"></i> {{ translate('edit') }}
                </q-btn>
            </q-item-section>
        </q-item>
        <q-item v-if="$q.screen.lt.sm" class="full-width p-0 mb-2">
            <q-item-section>
                <div class="text-h6">{{ translate('personal_data') }}</div>
            </q-item-section>
            <q-item-section side>
                <q-btn flat round class="full-width" no-caps color="primary" type="button" @click="showEditPanel">
                    <i class="fas fa-edit"></i>
                </q-btn>
            </q-item-section>
        </q-item>
        <q-separator />
        <q-item class="full-width mt-2 p-0">
            <q-item-section>
                <span class="text-muted">{{ translate('first_name') }}</span>
                {{ customer.first_name }}
            </q-item-section>
        </q-item>
        <q-item class="full-width mt-2 p-0">
            <q-item-section>
                <span class="text-muted">{{ translate('last_name') }}</span>
                {{ customer.last_name }}
            </q-item-section>
        </q-item>
        <q-item class="full-width mt-2 p-0">
            <q-item-section>
                <span class="text-muted">{{ translate('gender') }}</span>
                {{ customer.gender === 'f' ? translate('gender_female') : translate('gender_male') }}
            </q-item-section>
        </q-item>
        <q-item class="full-width mt-2 p-0">
            <q-item-section>
                <span class="text-muted">{{ translate('age') }}</span>
                {{ customer_age }}
            </q-item-section>
        </q-item>
        <q-item class="full-width mt-2 p-0">
            <q-item-section>
                <span class="text-muted">{{ translate('birth_date') }}</span>
                {{ prettyDate(customer.birth_date) }}
            </q-item-section>
        </q-item>
        <q-item class="full-width mt-2 p-0">
            <q-item-section>
                <span class="text-muted">{{ translate('phone_number') }}</span>
                {{ customer.phone_number }}
            </q-item-section>
        </q-item>
        <q-item class="full-width mt-2 p-0">
            <q-item-section>
                <span class="text-muted">{{ translate('email') }}</span>
                {{ customer.email }}
            </q-item-section>
        </q-item>
    </b-col>
</b-row>
</template>

<script>
import { eventBus } from '../../../main'
import EditPersonalData from '../forms/EditPersonalData'
import ProfilePicture from '../../template-parts/ProfilePicture'
import DeleteProfileImage from '../../template-parts/DeleteProfileImage'

export default {
    name: 'PersonalData',
    props: ['customer_id'],
    components: {
        'profile-picture': ProfilePicture,
    },
    computed: {
        delete_profile_image_route: function () {
            return baseUrl + 'profile_images/customer/' + this.customer_id
        },
    },
    data: function () {
        return {
            selectedFile: null,
            customer: {
                phone_number: '-',
            },
        }
    },
    created: function () {
        eventBus.$on('updateCustomer', (item) => {
            this.getCustomer(item.id)
        })
        eventBus.$on('updateCurrentUserProfileImage', (item) => {
            this.customer.image_link = null
        })
        this.$on('transition', this.getCustomer())
    },
    computed: {
        image_upload_route: function () {
            return baseUrl + 'profile_images/customer'
        },
        post_route: function () {
            return baseUrl + 'customers/'
        },
        customer_age: function () {
            var age = ''
            if (this.customer && this.customer.birth_date) {
                var age_milliseconds = Date.now() - new Date(this.customer.birth_date).getTime()
                var age_seconds = age_milliseconds / 1000
                var year_seconds = (60 * 60 * 24 * 365)
                
                var current_month = new Date().getMonth()
                var current_date = new Date().getDate()
                age = parseInt(age_seconds / year_seconds)
                if (current_month == new Date(this.customer.birth_date).getMonth() && current_date < new Date(this.customer.birth_date).getDate()) {
                    age = parseInt(age_seconds / year_seconds) - 1
                }
            }
            return age
        },
    },
    methods: {
        onFileSelected: function (event) {
            this.selectedFile = event.target.files[0]
            this.selectedFile.image_name = this.selectedFile.name.split(' ').join('_')
            this.uploadImage()
        },
        uploadImage: function () {
            const formData = new FormData()
            var file_name = this.selectedFile.name.split(' ').join('_')
            formData.append('file', this.selectedFile, file_name)
            var customer_id = this.customer_id
            if (this.$appSettings.currentUserType() === 'customer') {
                customer_id = this.customer.customer_id
            }
            formData.set('customer_id', this.customer_id)

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'multipart/form-data'
            }
            axios.post(this.image_upload_route, formData, {
                    headers: headers
                })
                .then(response => {
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = 'images_' + response.data.msg + key
                    var translated_message = this.translate(message)
                    
                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        eventBus.$emit('updateCustomer', { id: this.customer_id })
                        if (this.$appSettings.currentUserType() === 'customer') {
                            eventBus.$emit('updateCurrentUserProfileImage', { id: this.customer_id })
                        }
                        this.selectedFile = null
                        // Update image.
                        this.getCustomer()
                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }

                })
                .catch(error => {
                    // console.log(error)
                })
        },
        getCustomer: function (id) {
            var customer_id = id ? id : this.customer_id
            var url = baseUrl + 'customers/' + customer_id
            if (this.$appSettings.currentUserType() === 'customer') {
                url = baseUrl + 'users/current'
            }
            
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    if (response.data.item) {
                        this.customer = response.data.item
                        this.customer.image_link = response.data.item.image_link ? baseUrl + response.data.item.image_link : null
                    }
                    eventBus.$emit('hideTabs')
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        showDeleteProfileImagePanel: function () {
            const panelInstance = this.$showPanel({
                component: DeleteProfileImage,
                props: {
                    delete_profile_image_route: baseUrl + 'profile_images/customer/' + this.customer_id,
                    user_id: this.customer_id
                }
            })
        },
        showEditPanel: function () {
            const panelInstance = this.$showPanel({
                component: EditPersonalData,
                props: {
                    customer_id: this.customer_id
                }
            })

            panelInstance.promise
                .then(result => {
                    this.getCustomer()
                })
        },
    },
}
</script>