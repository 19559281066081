<template>
<div class="animated fadeIn fit">
    <b-card no-header no-body class="p-0" :class="$q.screen.lt.sm ? 'mb-0' : 'mb-4'">
        <q-item v-if="!type_worker" class="full-width">
            <q-item-section avatar>
                <q-avatar class="shadow-2">
                    <img v-if="customer.image_link" :src="customer.image_link">
                    <i v-else class="fas fa-user fa-1x text-muted"></i>
                </q-avatar>
            </q-item-section>
            <q-item-section>
                <span class="text-muted">{{translate('personal_file')}}</span>
                <h4 class="mb-0 align-self-start">{{ customer.name }}</h4>
            </q-item-section>
        </q-item>
        <template v-else>
            <choose-customer :initial_customer_id="customer.id" :initial_customer_name="customer.name" :initial_customer_image="customer.image_link" />
        </template>
    </b-card>
    <div v-if="$q.screen.lt.sm" class="text-right">
        <q-btn icon="menu" @click.prevent="show_tabs=!show_tabs" flat color="primary" />
    </div>
    <q-tabs :class="mobile_tabs" clickable hoverable no-caps v-model="tab" class="mb-1 personal-file-tabs" align="left">
        <q-tab class="custom-tab text-positive" name="personal_data">
            <div>
                <div class="tab-text">
                    <i class="fas fa-book text-positive mr-2 fa-lg"></i> <strong class="text-dark">{{ translate('personal_data') }}</strong>
                </div>
            </div>
        </q-tab>
        <q-tab class="custom-tab text-negative" name="medical_problems">
            <div>
                <div class="tab-text">
                    <i class="fas fa-heart-broken text-negative mr-2 fa-lg"></i> <strong class="text-dark">{{ translate('medical_problems') }}</strong>
                </div>
            </div>
        </q-tab>
        <q-tab class="custom-tab text-orange" name="measurements">
            <div>
                <div class="tab-text">
                    <i class="fas fa-ruler text-orange mr-2 fa-lg"></i> <strong class="text-dark">{{ translate('measurements') }}</strong>
                </div>
            </div>
        </q-tab>
        <q-tab class="custom-tab text-accent" name="equipment">
            <div>
                <div class="tab-text">
                    <i class="fas fa-chair text-accent mr-2 fa-lg"></i> <strong class="text-dark">{{ translate('equipment') }}</strong>
                </div>
            </div>
        </q-tab>
        <q-tab class="custom-tab text-blue" name="objectives">
            <div>
                <div class="tab-text">
                    <i class="fas fa-trophy text-blue mr-2 fa-lg"></i> <strong class="text-dark">{{ translate('objectives') }}</strong>
                </div>
            </div>
        </q-tab>
        <q-tab class="custom-tab text-blue-10" name="agenda">
            <div>
                <div class="tab-text">
                    <i class="fas fa-atom text-blue-10 mr-2 fa-lg"></i> <strong class="text-dark">{{ translate('personal_agenda') }}</strong>
                </div>
            </div>
        </q-tab>
        <q-tab class="custom-tab text-indigo" name="trainings">
            <div>
                <div class="tab-text">
                    <i class="fas fa-dumbbell text-indigo mr-2 fa-lg"></i> <strong class="text-dark">{{ translate('organized_trainings') }}</strong>
                </div>
            </div>
        </q-tab>
        <q-tab v-if="!type_customer" class="custom-tab text-amber" name="notes">
            <div>
                <div class="tab-text">
                    <i class="fas fa-clipboard text-amber mr-2 fa-lg"></i> <strong class="text-dark">{{ translate('notes') }}</strong>
                </div>
            </div>
        </q-tab>
    </q-tabs>
    <b-card no-header no-body class="m-0 mb-4 p-0 overflow-hidden">
        <template v-if="is_personal_data">
            <personal-data :customer_id="id" />
        </template>
        <template v-if="is_objectives">
            <objectives :customer_id="id" :customer_name="customer.name" />
        </template>
        <template v-if="is_trainings">
            <trainings :customer_id="id" />
        </template>
        <template v-if="is_medical_problems">
            <medical-problems :customer_id="id" :customer_name="customer.name" />
        </template>
        <template v-if="is_measurements">
            <measurements :customer_id="id" :customer_name="customer.name" />
        </template>
        <template v-if="is_equipment">
            <equipment :customer_id="id" />
        </template>
        <template v-if="is_agenda">
            <agenda :customer_id="id" :customer_image_link="customer.image_link" :customer_name="customer.name" />
        </template>
        <template v-if="is_notes && !type_customer">
            <notes :customer_id="id" :customer_name="customer.name" />
        </template>
    </b-card>
</div>
</template>

<script>
import _ from 'lodash'
import * as jwt_decode from 'jwt-decode'
import { eventBus } from '../../main'
import PersonalData from './tab-panels/PersonalData'
import Objectives from './tab-panels/Objectives'
import Trainings from './tab-panels/Trainings'
import MedicalProblems from './tab-panels/MedicalProblems'
import Measurements from './tab-panels/Measurements'
import Notes from './tab-panels/Notes'
import Equipment from './tab-panels/Equipment'
import Agenda from './tab-panels/Agenda'
import ChooseCustomer from '../template-parts/ChooseCustomer'

export default {
    name: 'PersonalFile',
    components: {
        'personal-data': PersonalData,
        'objectives': Objectives,
        'trainings': Trainings,
        'medical-problems': MedicalProblems,
        'measurements': Measurements,
        'notes': Notes,
        'equipment': Equipment,
        'agenda': Agenda,
        'choose-customer': ChooseCustomer
    },
    computed: {
        is_personal_data: function () {
            return this.tab === 'personal_data'
        },
        is_objectives: function () {
            return this.tab === 'objectives'
        },
        is_trainings: function () {
            return this.tab === 'trainings'
        },
        is_medical_problems: function () {
            return this.tab === 'medical_problems'
        },
        is_measurements: function () {
            return this.tab === 'measurements'
        },
        is_equipment: function () {
            return this.tab === 'equipment'
        },
        is_agenda: function () {
            return this.tab === 'agenda'
        },
        is_notes: function () {
            return this.tab === 'notes'
        },
        mobile_tabs: function () {
            if (this.$q.screen.lt.sm) {
                return !this.show_tabs ? 'hidden' : 'mobile-tabs'
            }
            return ''
        },
        id: {
            get () {
                return this.$route.params.id > 0 ? this.$route.params.id : 0
            },
            set (value) {
                return this.$route.params.id = value
            },
        },
    },
    data: function () {
        return {
            show_tabs: this.$q.screen.lt.sm ? false : true,
            type_customer: this.$appSettings.currentUserType() === 'customer' ? true : false,
            type_worker: this.$appSettings.currentUserType() === 'worker' ? true : false,
            tab: 'trainings',
            customer: {},
            show_customers: false,
        }
    },
    created: function () {
        eventBus.$on('updateCustomer', (item) => {
            this.updateCustomer(item)
        })
        eventBus.$on('hideTabs', () => {
            this.hideTabs()
        })
        this.getCustomer()
    },
    methods: {
        hideTabs: function () {
            this.show_tabs = false
        },
        updateCustomer: function (item) {
            this.id = item.id
            this.getCustomer()
        },
        getCurrentCustomerId: function () {
            this.token = this.accessToken()
            var decoded = jwt_decode(this.token)
            var id = 0

            if (decoded.identity.customer_id) {
                id = decoded.identity.customer_id
            }
            return id
        },
        getCustomer: function () {
            if (this.type_customer) {
                this.id = this.getCurrentCustomerId()
            }
            
            if (this.id) {
                var url = baseUrl + 'customers/' + this.id
                var headers = {
                    'Authorization': 'Bearer ' + this.accessToken(),
                }
                axios.get(url, {
                        headers: headers
                    })
                    .then(response => {
                        if (response.data.item) {
                            this.customer = response.data.item
                            this.customer.name = response.data.item.first_name + ' ' + response.data.item.last_name
                            this.customer.image_link = response.data.item.image_link ? baseUrl + response.data.item.image_link : null
                        }
                    })
                    .catch(function (error) {
                        // console.log(error)
                    })
            }
        },
    },
}
</script>

<style lang="scss" scoped>
body .custom-tab {
    background-clip: border-box;
    border: 1px solid #c8ced3;
    border-radius: 0.25rem;
    margin: 0 .25rem 0 0;
    padding: 0;
    background: #fff;

    &:hover {
        background: inherit;
    }
    div {
        display: block;
    }
    .image-holder {
        // width: 300px;
        width: 215px;
        display: block;
        height: 90px;
        margin: -4px 0 0.25rem;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .tab-text {
        // max-width: 185px;
        max-width: 100%;
        padding: 2px 15px 4px;
    }
}

@media screen and (max-width: 320px) {
    body .custom-tab {
        .image-holder {
            width: 200px;
        }
        .tab-text {
            max-width: 170px;
        }
    }
}
.align-self-start {
    display: inline!important;
}
</style>
