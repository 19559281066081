<template>
<div class="fit">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless>
        <b-tab class="p-0">
            <template slot="title">
                <q-icon name="archive" />
            </template>
            <b-list-group class="list-group-accent">
                <b-list-group-item class="list-group-item-accent-secondary bg-light font-weight-bold text-uppercase">
                    <q-icon name="add" />&nbsp;
                    {{ translate(entity_type + '_settings') }}
                </b-list-group-item>
                <b-list-group-item class="list-group-item-accent-primary bg-custom-white" v-if="!$q.screen.lt.sm">
                    <q-item class="p-0">
                        <q-item-section side><q-icon color="primary" size="md" name="fas fa-user" /></q-item-section>
                        <q-item-section>
                            <small class="text-muted">{{ translate('customer') }}</small>
                            <strong>{{ customer_name }}</strong>
                        </q-item-section>
                        <q-item-section side>
                            <small class="text-muted">{{ translate('objective') }}</small>
                            <strong>{{ item.objective_name }}</strong>
                            <small class="text-muted">{{ date }}</small>
                        </q-item-section>
                        <q-item-section side><q-icon color="primary" size="md" name="fas fa-bullseye" /></q-item-section>
                    </q-item>
                </b-list-group-item>
                <b-list-group-item class="list-group-item-accent-primary bg-custom-white" v-if="$q.screen.lt.sm">
                    <q-item class="pl-0 pr-0">
                        <q-item-section side><q-icon color="primary" size="md" name="fas fa-user" /></q-item-section>
                        <q-item-section>
                            <small class="text-muted">{{ translate('customer') }}</small>
                            <strong>{{ customer_name }}</strong>
                        </q-item-section>
                    </q-item>
                </b-list-group-item>
                <q-separator v-if="$q.screen.lt.sm" />
                <b-list-group-item class="list-group-item-accent-primary bg-custom-white" v-if="$q.screen.lt.sm">
                    <q-item class="pl-0 pr-0">
                        <q-item-section side><q-icon color="primary" size="md" name="fas fa-bullseye" /></q-item-section>
                        <q-item-section>
                            <small class="text-muted">{{ translate('objective') }}</small>
                            <strong>{{ item.objective_name }}</strong>
                            <small class="text-muted">{{ date }}</small>
                        </q-item-section>
                    </q-item>
                </b-list-group-item>
                <q-separator />
                <b-list-group-item class="list-group-item-accent-info">
                    <q-item class="p-0">
                        <q-item-section side><q-icon color="accent" size="md" name="fas fa-calendar" /></q-item-section>
                        <q-item-section>
                            <small class="text-muted">{{ translate('deadline') }}</small>
                            <strong>{{ item.deadline ? dateFormatShortMonth(item.deadline) : translate('nope') }}</strong>
                            <small class="text-muted">{{ translate('finished_at') }}</small>
                            <strong>{{ item.finished_at ? dateFormatShortMonth(item.finished_at) : translate('not_yet') }}</strong>
                        </q-item-section>
                    </q-item>
                </b-list-group-item>
                <q-separator />
                <b-list-group-item class="list-group-item-accent-info">
                    <q-item class="full-width p-0">
                        <q-item-section class="p-0">
                            <small class="text-muted">{{ translate('description') }}</small>
                            <span :class="!item.description ? 'text-muted' : ''">{{ item.description ? item.description : translate('no_description') }}</span>
                        </q-item-section>
                    </q-item>
                </b-list-group-item>
                <q-separator v-if="!type_customer" />
                <b-list-group-item v-if="!type_customer" class="list-group-item-accent-info">
                    <q-item class="full-width p-0">
                        <q-space />
                        <q-item-section class="p-0 pr-1 border-right" side>
                            <q-btn dense color="danger" @click.prevent="showDeleteItemPanel(item.id)" icon="delete" flat round />
                            <q-tooltip :offset="[10, 10]">{{ translate('delete_' + entity_type) }}</q-tooltip>
                        </q-item-section>
                        <q-item-section class="p-0" side>
                            <q-toggle 
                                @input="toggleActive(objective_status)" 
                                v-model="objective_status" 
                                dense keep-color 
                                checked-icon="checked" 
                                unchecked-icon="clear" 
                                :label="objective_status" 
                                :indeterminate-value="'pending'" 
                                :true-value="'achieved'" 
                                :false-value="'failed'" 
                                :color="statusToggleColor(objective_status)" 
                            />
                            <q-tooltip :offset="[10, 10]">{{ translate('mark_' + entity_type) }}</q-tooltip>
                        </q-item-section>
                    </q-item>
                </b-list-group-item>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import {
    eventBus
} from "../../../main"

import IncludeItemForm from './IncludeItem'
import DeleteItem from './DeleteItem'

export default {
    name: 'EditPersonalObjective',
    props: ['objective', 'customer_id', 'customer_name', 'entity_type', 'get_route', 'date'],
    data: function () {
        return {
            type_customer: this.$appSettings.currentUserType() === 'customer' ? true : false,
            item: {
                description: null
            },
            objective_status: this.objective.objective_status
        }
    },
    created: function () {
        this.getItem()
    },
    mounted: function () {
        eventBus.$on('update_all', () => {
            this.getItem()
        })
    },
    computed: {
        month_names: function () {
            return [this.translate('January'), this.translate('February'), this.translate('March'), this.translate('April'), this.translate('May'), this.translate('June'),
                this.translate('July'), this.translate('August'), this.translate('September'), this.translate('October'), this.translate('November'), this.translate('December')
            ]
        },
        delete_route: function () {
            return baseUrl + 'personal/objectives'
        },
        panel_title: function () {
            return this.translate('delete_personal_objective')
        },
        panel_message: function () {
            return this.translate('deleting_personal_objective')
        },
    },
    methods: {
        dateFormatShortMonth: function (date) {
            console.log(date)
            var month = this.month_names[new Date(date).getMonth()], day = new Date(date).getDate(), year = new Date(date).getFullYear()
            var formatted_date = month.substring(0,3) + ' ' + day + ', ' + year

            if (this.$appSettings.lang == 'ro') {
                formatted_date = day + ' ' + month.substring(0,3) + ', ' + year
            }

            return formatted_date
        },
        statusToggleColor: function (status) {
            var color = 'green'
            if (status === 'failed') {
                color = 'red'
            }
            if (status === 'pending') {
                color = 'blue'
            }

            return color
        },
        toggleActive: function (status) {
            if (status === 'achieved') {
                var achieved = 1
            } else {
                var achieved = 0
            }
            var url = baseUrl + 'personal/objectives/' + this.objective.id + '/achieved/' + achieved

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken()
            }
            
            var data = {
                
            }
            axios.patch(url, data, {
                    headers: headers
                })
                .then(response => {
                    var message = 'personal_objective_updated'
                    var translated_message = this.translate(message)
                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        eventBus.$emit('update_all')
                    } else {
                        this.$toasted.error('Something went wrong, please try again', {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(function (error) {
                    // console.log(error);
                })
        },
        getItem: function () {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }
            var url = this.get_route + '/' + this.objective.id
            
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    this.item = response.data.item
                    // if (response.data.item.finished_at) {
                    //     var finish_date = new Date(response.data.item.finished_at)
                    //     var year = finish_date.getFullYear(), month = finish_date.getMonth(), date = finish_date.getDate()
                    //     this.item.finished_at = year + '-' + (month < 10 ? '0' + month : month) + '-' + (date < 10 ? '0' + date : date)
                    // }
                    
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        showDeleteItemPanel: function (id) {
            const panelInstance = this.$showPanel({
                component: DeleteItem,
                props: {
                    item_id: id,
                    entity_type: this.entity_type,
                    delete_route: this.delete_route,
                    panel_title: this.panel_title,
                    panel_message: this.panel_message
                }
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.$emit('closePanel', {
                            status: true
                        })
                    }
                })
        },
        closePanel: function () {
            this.$emit('closePanel', {})
        }
    }
}
</script>

<style lang="scss" scoped>
.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: .5rem 0;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }
}
.border-right {
    border-right: 1px solid red;
}
</style>
