<template>
<div>
    <template v-if="$appSettings.currentUserType() === 'customer'">
        <q-item class="full-width p-0 mb-2">
            <q-item-section>
                <div class="text-h6">{{ translate('organized_trainings') }}</div>
            </q-item-section>
        </q-item>
    </template>
    <div class="mt-4 mb-2 text-bold">
        <i class="text-indigo fas fa-history mr-2"></i>{{ translate('organized_trainings') }}
    </div>
    <template>
        <q-timeline color="light-blue-9" class="list_wrapper-personal_trainings trainings-timeline">
            <q-timeline-entry v-for="(training,i) in trainings" :key="'training-' + i + '-' + training.id">
                <template slot="title">
                    <q-item @click.stop="training.show = !training.show" clickable class="m-0 p-0 cursor-pointer col-xs-12 col-md-5" style="min-height: 0;" :class="$q.screen.xs ? 'small' : ''">
                        <q-item-section side class="text-light-blue-9 bg-lightrey-2 col-8 p-0 arrow-right">
                            <div class="p-1 pl-2 pr-2 font-weight-normal">
                                <i :class="training.show ? 'fas fa-angle-up' : 'fas fa-angle-down'" class="mr-1" size="sm"></i>
                                <template>{{ prettyDate(training.date) + ' ' + training.start_time.slice(0, -3) }}</template>
                            </div>
                        </q-item-section>
                        <q-item-section class="text-center bg-lightrey-2 p-0">
                            <div class="text-black p-1 pl-2 pr-2">{{training.service_name}}</div>
                        </q-item-section>
                    </q-item>
                </template>
                <q-card v-if="training.show === true" class="logged-list col-xs-12 p-0 overflow-hidden shadow-0">
                    <div class="m-0 p-0 row align-items-start">
                        <div class="col-xs-12 col-md-5 p-0">
                            <div v-if="training.training_name || training.exercises || (training.muscles && training.muscles.length)">
                                <q-btn flat :color="training.exercises ? 'accent' : 'indigo'" icon="fas fa-dumbbell" :size="$q.screen.xs ? 'sm' : '0.75rem'" dense no-caps :label="training.exercises ? translate('exercises') : (training.training_name ? translate('training') : '')" />
                                <q-btn v-if="training.training_name" @click.stop="getTrainingExercises(training.training_id, training)" class="pull-right" flat color="green-6" icon="fas fa-running" :icon-right="training.exercises_collections && training.exercises_collections.length ? 'fas fa-chevron-up' : 'fas fa-chevron-right'" size="sm" dense no-caps :label="training.exercises_collections && training.exercises_collections.length ? translate('hide_exercises') : translate('see_exercises')" />
                            </div>
                            <q-separator class="mt-1 mb-2" />
                            <template v-if="training.show === true && (training.training_name || training.exercises || (training.muscles && training.muscles.length))">
                                <template v-if="training.training_name">
                                    <q-card-section class="p-0">
                                        <div class="bg-lightrey-2 pl-2 pr-2 pt-1 pb-1 font-weight-bold text-dark">
                                            {{ training.training_name }}
                                        </div>
                                        <div class="p-2">
                                            {{ training.notes ? training.notes : '-' }}
                                        </div>
                                    </q-card-section>
                                    <!--[+] training's exercises collections -->
                                    <q-card class="p-2 bg-light mb-2" bordered square v-if="training.exercises_collections && training.exercises_collections.length">
                                        <strong class="text-green-6"><i class="fas fa-running mr-1"></i>{{ translate('training_exercises') }}</strong>
                                        <div v-for="(collections,c) in training.exercises_collections" :key="'exercise-collection-' + c + '-' + training.training_id">
                                            <q-item v-for="(exercise_collection,ec) in collections" class="list-item" :key="'training-exercise-collection-' + ec + '-' + training.training_id">
                                                <q-item-section class="font-weight-bold small text-blue-8" top side>{{ ec+1 }}</q-item-section>
                                                <q-item-section>
                                                    <q-item-label line="1">
                                                        <template v-if="exercise_collection.rounds">
                                                            <!-- rounds -->
                                                            <strong class="text-blue">{{ exercise_collection.rounds }}</strong> <span class="mr-1">x</span>
                                                        </template>
                                                        <template v-if="exercise_collection.seconds">
                                                            <!-- seconds -->
                                                            <strong class="text-primary mr-1">{{ secondsToFormattedTime(exercise_collection.seconds) }}</strong>
                                                        </template>
                                                        <!-- exercise_exercise_collection_name -->
                                                        <strong class="text-blue-10">{{ exercise_collection.name }}</strong>
                                                    </q-item-label>
                                                    <q-item-label line="2" v-if="exercise_collection.description">{{ exercise_collection.description }}</q-item-label>
                                                    <q-item-label line="3">
                                                        <q-item v-for="(exercise,e) in exercise_collection.exercises" class="list-item" :key="'training-collection-exercise-' + e + '-' + exercise_collection.id">
                                                            <q-item-section class="font-weight-bold small text-blue-8" top side>{{ e+1 }}</q-item-section>
                                                            <q-item-section>
                                                                <span>
                                                                    <!-- exercise_name -->
                                                                    <strong class="text-blue-10 mr-1">{{ exercise.name }}</strong>
                                                                    <!-- reps -->
                                                                    <span class="mr-1">x</span><strong class="mr-1">{{ exercise.reps }}</strong>
                                                                    <span class="mr-1">@</span>
                                                                    <!-- value unit -->
                                                                    <strong class="mr-1">{{ exercise.value_per_rep }}{{ exercise.unit_name }}</strong>
                                                                </span>
                                                            </q-item-section>
                                                        </q-item>
                                                    </q-item-label>
                                                </q-item-section>
                                            </q-item>
                                        </div>
                                        <div class="text-right">
                                            <q-btn @click.stop="getTrainingExercises(training.training_id, training)" flat color="green-6" icon="fas fa-running" :icon-right="training.exercises_collections && training.exercises_collections.length ? 'fas fa-chevron-up' : 'fas fa-chevron-right'" size="sm" dense no-caps :label="training.exercises_collections && training.exercises_collections.length ? translate('hide_exercises') : translate('see_exercises')" />
                                        </div>
                                    </q-card>
                                    <!--[-] training's exercises collections -->
                                </template>
                                <template v-if="training.exercises">
                                    <q-card-section class="p-0">
                                        <div v-for="(exercise,i) in training.exercises" :key="'log-exercise-' + i + '-' + exercise.exercise_id">
                                            <div class="bg-lightrey-2 pl-2 pr-2 pt-1 pb-1 font-weight-bold text-dark">
                                                <template v-if="((i + 1) < 10)">0</template>{{ (i + 1) + '. ' + exercise.exercise_name }}
                                            </div>
                                            <div class="p-2">
                                                {{ exercise.notes ? exercise.notes : '-' }}
                                            </div>
                                        </div>
                                    </q-card-section>
                                </template>
                                <template v-if="training.muscles && training.muscles.length">
                                    <q-card-section class="p-0">
                                        <div class="text-subtitle3">{{ translate('muscles') }}</div>
                                        <q-separator />
                                        <span v-for="(muscles,i) in training.muscles" :key="'logged-training-muscle-' + i">
                                            <span v-for="(m,j) in muscles" :key="'muscle-' + i + '-' + j">
                                                {{ m }}<template v-if="i+1 < training.muscles.length">, </template>
                                            </span>
                                        </span>
                                    </q-card-section>
                                </template>
                            </template>
                            <template v-if="training.show === true && !training.training_name && !training.exercises && !training.muscles">
                                <q-card-section class="text-muted">
                                    {{ translate('no_results') }}
                                </q-card-section>
                            </template>
                        </div>
                        <div v-if="training.show === true" class="col-xs-12 col-md p-2 p-md-4">
                            <body-svg :paths="training.muscles" :schedule_id="training.schedule_id" />
                        </div>
                    </div>
                </q-card>
            </q-timeline-entry>
        </q-timeline>
        <infinite-loading :identifier="infPersonalTrainings" slot="append" @infinite="getItems" />
    </template>
    <template v-if="!trainings && trainings.length == 0">
        <q-item class="list-group-item-accent-dark bg-light text-dark mt-2">
            <q-item-section>
                <div>
                    {{ translate('no_available_trainings_yet_use_the_button_below_to_start_adding') }}<br />
                    <b-button variant="primary" type="button" @click="showAssignTrainingPanel">
                        <span class=""><i class="fas fa-plus"></i> {{ translate('personal_trainings') }}</span>
                    </b-button>
                </div>
            </q-item-section>
        </q-item>
    </template>
</div>
</template>

<script>
import {
    eventBus
} from "../../../main"
import AssignTraining from '../forms/AssignTraining'
import BodySVG from '../template-parts/BodySVG'

export default {
    name: 'OrganizedTrainings',
    props: ['customer_id'],
    components: {
        'body-svg': BodySVG,
    },
    data: function () {
        return {
            type_customer: this.$appSettings.currentUserType() === 'customer' ? true : false,
            trainings: [],
            page: 1,
            state: {},
            infPersonalTrainings: +new Date()
        }
    },
    mounted: function () {
        this.$on('transition', this.getItems)
        eventBus.$emit('hideTabs')
        eventBus.$on('update_included_items', () => {
            this.resetMainList()
        })
        eventBus.$on('updateCustomer', (item) => {
            this.resetMainList()
        })
    },
    created: function () {},
    computed: {
        post_route: function () {
            return baseUrl + 'personal/trainings'
        },
        get_route: function () {
            return baseUrl + 'logged_schedules'
        },
        get_trainings_route: function () {
            return baseUrl + 'trainings'
        },
        entity_type: function () {
            return 'logged_schedule'
        },
        main_title: function () {
            return this.translate('organized_trainings')
        },
        placeholder_find_item: function () {
            return this.translate('find_training')
        },
    },
    methods: {
        secondsToFormattedTime: function (seconds) {
            const formatted_time = new Date(seconds * 1000).toISOString().substr(11, 8)
            return formatted_time
        },
        getTrainingExercises: _.debounce(function (training_id, training) {
            // Remove the list to toggle (hide) the vizibility of the list.
            if (training['exercises_collections'].length) {
                training['exercises_collections'] = []
                return
            }
            // Empty the list to put new fresh list of items in the array.
            training['exercises_collections'] = []
            
            var url = baseUrl + 'trainings/' + training_id
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            let list = []
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    if (response.data.item && response.data.item.exercises_collections) {
                        list.push(response.data.item.exercises_collections)
                        training['exercises_collections'] = list
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        }, 200),
        resetMainList: function () {
            this.page = 1
            this.trainings = []
            this.infPersonalTrainings++
        },
        getItems: function ($state) {
            var url = this.get_route + '/customer/' + this.customer_id + '?page=' + this.page + '&items_per_page=' + this.$items_per_page
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            this.state = $state
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (response.data.items && response.data.items.length > 0) {
                        this.trainings.push(...response.data.items.map(entity => {
                            let array = entity
                            array['exercises_collections'] = []
                            array['show'] = false
                            return array
                        }))
                    }
                    if (response.data.next_page === true) {
                        this.page++
                        $state.loaded()
                    } else {
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        showAssignTrainingPanel: function () {
            const panelInstance = this.$showPanel({
                component: AssignTraining,
                props: {
                    customer_id: this.customer_id,
                    post_route: this.post_route,
                    get_route: this.get_trainings_route,
                    item_id: this.customer_id,
                    entity_type: this.entity_type,
                    main_title: this.main_title,
                    placeholder_find_item: this.placeholder_find_item,
                }
            })

            panelInstance.promise
                .then(result => {
                    return this.resetMainList()
                })
        },
    },
}
</script>
