<template>
<div class="fit">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless>
        <b-tab class="p-0">
            <template slot="title">
                <q-icon name="archive" />
            </template>
            <b-list-group class="list-group-accent">
                <b-list-group-item class="list-group-item-accent-secondary bg-light font-weight-bold text-uppercase">
                    <q-icon name="add" />&nbsp;
                    {{ translate(entity_type + '_settings') }}
                </b-list-group-item>
                <b-list-group-item class="list-group-item-accent-primary bg-custom-white" v-if="!$q.screen.lt.sm">
                    <q-item class="p-0">
                        <q-item-section side><q-icon color="primary" size="md" name="fas fa-user" /></q-item-section>
                        <q-item-section>
                            <small class="text-muted">{{ translate('customer') }}</small>
                            <strong>{{ customer_name }}</strong>
                        </q-item-section>
                    </q-item>
                </b-list-group-item>
                <q-separator />
                <b-list-group-item class="list-group-item-accent-info bg-custom-white">
                    <q-item class="pl-0 pr-0">
                        <q-item-section side><q-icon color="info" size="md" name="fas fa-heart-broken" /></q-item-section>
                        <q-item-section>
                            <small class="text-muted">{{ translate('measurement') }}</small>
                            <strong>{{ item.measurement_name }}</strong>
                            <small class="text-muted">{{ date }}</small>
                        </q-item-section>
                    </q-item>
                </b-list-group-item>
                <q-separator />
                <b-list-group-item class="list-group-item-accent-info">
                    <q-item class="full-width p-0">
                        <q-item-section class="p-0">
                            <small class="text-muted">{{ translate('measurement_value') }}</small>
                            <span :class="!item.measurement_value ? 'text-muted' : ''">{{ item.measurement_value ? item.measurement_value : translate('measurement_no_value') }} {{ item.measurement_unit }}</span>
                        </q-item-section>
                    </q-item>
                </b-list-group-item>
                <q-separator v-if="!type_customer" />
                <b-list-group-item v-if="!type_customer" class="list-group-item-accent-info">
                    <q-item class="full-width p-0">
                        <q-space />
                        <q-item-section class="p-0 pr-1" side>
                            <q-btn dense color="danger" @click.prevent="showDeleteItemPanel(item.id)" icon="delete" flat round />
                            <q-tooltip :offset="[10, 10]">{{ translate('delete_' + entity_type) }}</q-tooltip>
                        </q-item-section>
                    </q-item>
                </b-list-group-item>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import {
    eventBus
} from "../../../main"

import IncludeItemForm from './IncludeItem'
import DeleteItem from './DeleteItem'

export default {
    name: 'PersonalMeasurementSettings',
    props: ['measurement', 'customer_id', 'customer_name', 'entity_type', 'get_route', 'date'],
    data: function () {
        return {
            type_customer: this.$appSettings.currentUserType() === 'customer' ? true : false,
            item: {
                description: ''
            },
        }
    },
    created: function () {
        this.getItem()
    },
    computed: {
        delete_route: function () {
            return baseUrl + 'personal/measurements'
        },
        panel_title: function () {
            return this.translate('delete_personal_measurement')
        },
        panel_message: function () {
            return this.translate('deleting_personal_measurement')
        },
    },
    methods: {
        getItem: function () {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }
            var url = this.get_route + '/' + this.measurement.id
            
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    this.item = response.data.item
                    if (response.data.item.finished_at) {
                        var finish_date = new Date(response.data.item.finished_at)
                        var year = finish_date.getFullYear(), month = finish_date.getMonth(), date = finish_date.getDate()
                        this.item.finished_at = year + '-' + (month < 10 ? '0' + month : month) + '-' + (date < 10 ? '0' + date : date)
                    }
                    
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        showDeleteItemPanel: function (id) {
            const panelInstance = this.$showPanel({
                component: DeleteItem,
                props: {
                    item_id: id,
                    entity_type: this.entity_type,
                    delete_route: this.delete_route,
                    panel_title: this.panel_title,
                    panel_message: this.panel_message
                }
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.$emit('closePanel', {
                            status: true
                        })
                        // Fix issue from slideout closing panel.
                        document.querySelector('body').classList.remove('slideout-panel-open')
                    }
                })
        },
        closePanel: function () {
            this.$emit('closePanel', {})
        }
    }
}
</script>

<style lang="scss" scoped>
.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: .5rem 0;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }
}
</style>
