<template>
<b-row class="full-width">
    <b-col v-if="$q.screen.lt.sm" sm="12" md="2" class="bg-light p-3 p-md-4">
        <panel-picture :src="panel_picture" />
    </b-col>
    <b-col sm="12" class="p-3 p-md-4 bg-white">
        <q-item class="full-width p-0 mb-2">
            <q-item-section>
                <div class="text-h6">{{ translate('medical_problems') }}</div>
            </q-item-section>
            <q-item-section v-if="!type_customer" side>
                <q-btn v-if="!$q.screen.lt.sm" no-caps color="primary" type="button" @click="showAddPersonalMedicalProblemPanel">
                    <i class="fas fa-plus mr-1"></i> {{ translate('medical_problems') }}
                </q-btn>
                <q-btn v-if="$q.screen.lt.sm" flat round class="full-width" no-caps color="primary" type="button" @click="showAddPersonalMedicalProblemPanel">
                    <i class="fas fa-plus"></i>
                </q-btn>
            </q-item-section>
        </q-item>
        <q-separator />
        <div v-if="show_search === true" :class="this.$q.screen.lt.sm ? '' : 'bg-light p-4'">
            <search-wrapper-card :fullwidth_textinput="true" class="timeline-search shadow-2" :active="item_active" :activeStatus="activeStatus" :inactive="item_inactive" :inactiveStatus="inactiveStatus" :typeDisable="null" form_type="medical_problems">
                <q-input slot="search_input" bottom-slots @input="isTypingItem = true" v-model="searchItem" id="filternamepersonalmedical_problem" class="search-input" type="text" :placeholder="translate('find_personal_medical_problem')" :dense="true">
                    <template v-slot:prepend>
                        <q-icon name="search" />
                    </template>
                </q-input>
            </search-wrapper-card>
        </div>
        <div class="mt-4 text-bold mb-4">
            <i class="text-blue fas fa-history mr-2"></i>{{ translate('medical_problems_history') }}
        </div>
        <div v-if="medical_problems || medical_problems.length > 0" class="d-flex justify-content-left">
            <q-timeline color="blue" class="medical_problems-timeline list_wrapper-medical_problems col-md-6">
                <q-timeline-entry v-for="(medical_problem,i) in medical_problems" :key="'medical_problem-' + i + '-' + medical_problem.id" :color="timelineColor(medical_problem.is_active)" :title="medical_problem.medical_problem_name" :subtitle="prettyDate(medical_problem.created_at)">
                    <div>
                        <q-btn @click.prevent="showPersonalMedicalProblemSettingsPanel(medical_problem, prettyDate(medical_problem.created_at))" flat color="blue" icon="event" size="sm" dense no-caps :label="translate('details')" />
                    </div>
                </q-timeline-entry>
            </q-timeline>
            <infinite-loading :identifier="infPersonalMedicalProblems" slot="append" @infinite="getPersonalMedicalProblems" />
        </div>
        <template v-if="!medical_problems || medical_problems.length === 0">
            <q-item class="list-group-item-accent-dark bg-light text-dark mt-2">
                <q-item-section>
                    <div v-if="!type_customer">
                        {{ translate(no_results_message) }}<br />
                        <q-btn no-caps color="primary" type="button" @click="showAddPersonalMedicalProblemPanel">
                            <i class="fas fa-plus mr-1"></i> {{ translate('medical_problems') }}
                        </q-btn>
                    </div>
                    <div v-if="type_customer">
                        {{ translate('no_results') }}
                    </div>
                </q-item-section>
            </q-item>
        </template>
    </b-col>
</b-row>
</template>

<script>
import {
    eventBus
} from "../../../main"
import PanelPicture from '../../template-parts/PanelPicture'
import AddPersonalMedicalProblem from '../forms/AddPersonalMedicalProblem'
import PersonalMedicalProblemSettings from '../forms/PersonalMedicalProblemSettings'
import SearchWrapperCard from '../../template-parts/SearchWrapperCard'

export default {
    name: 'MedicalProblems',
    props: ['customer_id', 'customer_name'],
    components: {
        'panel-picture': PanelPicture,
        'search-wrapper-card': SearchWrapperCard,
    },
    data: function () {
        return {
            no_results_message: 'no_assigned_medical_problems_yet_use_the_button_below_to_start_adding',
            type_customer: this.$appSettings.currentUserType() === 'customer' ? true : false,
            show_search: true,
            searchItem: '',
            isTypingItem: false,
            medical_problems: [],
            page: 1,
            state: {},
            infPersonalMedicalProblems: +new Date(),
            item_active: 'active',
            item_inactive: '',
        }
    },
    computed:  {
        layout: function () {
            return this.$q.screen.lt.sm ? 'dense' : (this.$q.screen.lt.md ? 'comfortable' : 'loose')
        },
        panel_picture: function () {
            return '/img/personal-files/probleme-medicale.jpg'
        },
        post_route: function () {
            return baseUrl + 'personal/medical_problems'
        },
        get_route: function () {
            return baseUrl + 'personal/medical_problems'
        },
        get_medical_problems_route: function () {
            return baseUrl + 'medical_problems'
        },
        delete_route: function () {
            return baseUrl + 'personal/medical_problems'
        },
        entity_type: function () {
            return 'personal_medical_problem'
        },
        placeholder_find_item: function () {
            return this.translate('find_medical_problem')
        },
    },
    watch: {
        searchItem: _.debounce(function () {
            this.isTypingItem = false
        }, 200),
        isTypingItem: function (value) {
            if (!value) {
                this.resetMainList()

                if (!this.medical_problems || this.medical_problems.length === 0) {
                    this.no_results_message = 'no_medical_problems_found_on_search'
                }
            }
        }
    },
    created: function () {
        this.$on('transition', this.resetMainList())
        eventBus.$on('update_all', () => {
             this.resetMainList()
        })
        eventBus.$on('updateCustomer', (item) => {
            this.resetMainList()
        })
    },
    methods: {
        timelineColor: function (status) {
            var color = 'primary'
            
            if (status === false) {
                color = 'grey'
            }
            return color
        },
        resetMainList: function () {
            this.page = 1
            this.medical_problems = []
            this.infPersonalMedicalProblems++
            eventBus.$emit('hideTabs')
        },
        getPersonalMedicalProblems: function ($state) {
            var url = baseUrl + 'personal/medical_problems/customer/' + this.customer_id + '?page=' + this.page + '&items_per_page=' + this.$items_per_page
            if (this.item_active === 'active' && this.item_inactive === '') {
                url += '&status=active'
            }
            if (this.item_inactive === 'inactive' && this.item_active === '') {
                url += '&status=inactive'
            }
            if (this.searchItem) {
                url += '&search=' + this.searchItem
            }
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            this.state = $state
            // To solve latency with server, push the items into a temporary scoped array and then into the main array.
            let medical_problems = this.medical_problems
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (response.data.items && response.data.items.length > 0) {
                        medical_problems.push(...response.data.items)
                    }
                    if (response.data.next_page === true) {
                        this.page++
                        $state.loaded()
                    } else {
                        // To solve latency with server, push the items into a temporary scoped array and then into the main array.
                        this.medical_problems = medical_problems
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        showAddPersonalMedicalProblemPanel: function (medical_problem) {
            const panelInstance = this.$showPanel({
                component: AddPersonalMedicalProblem,
                props: {
                    medical_problem: medical_problem,
                    customer_id: this.customer_id,
                    post_route: this.post_route,
                    get_route: this.get_medical_problems_route,
                    entity_type: this.entity_type,
                    customer_name: this.customer_name,
                    placeholder_find_item: this.placeholder_find_item,
                }
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.resetMainList()
                    }
                })
        },
        showPersonalMedicalProblemSettingsPanel: function (medical_problem, date) {
            const panelInstance = this.$showPanel({
                component: PersonalMedicalProblemSettings,
                props: {
                    medical_problem: medical_problem,
                    customer_id: this.customer_id,
                    customer_name: this.customer_name,
                    entity_type: this.entity_type,
                    get_route: this.get_route, 
                    date: date
                }
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.$emit('closePanel')
                    }
                })
        },
        activeStatus: _.debounce(function () {
            if (this.item_active === 'active') {
                this.item_active = ''
            } else {
                this.item_active = 'active'
            }
            this.resetMainList()
        }, 200),
        inactiveStatus: _.debounce(function () {
            if (this.item_inactive === 'inactive') {
                this.item_inactive = ''
            } else {
                this.item_inactive = 'inactive'
            }
            this.resetMainList()
        }, 200),
    },
}
</script>
